import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/api';


const Register = () => {
  const [name, setName] = useState('');
  const [mobile, setMobileNo] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [newUserDetails, setNewUserDetails] = useState(null);
  const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!name) {
      setError('Please enter your name');
      return;
    }
    if (!mobile) {
      setError('Please enter your mobile');
      return;
    }

    if (!/^\d{10}$/.test(mobile)) {
      setError('Mobile number should be 10 digits');
      return;
    }

    if (email && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setError('Please enter valid email');
      return;
    }

    if (!referralCode) {
      setError('Please enter a referral code');
      return;
    }

    if (!password) {
      setError('Please enter a password');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const { data } = await axios.post('/user/register', { name, email, password, referralCode, mobile });
      setNewUserDetails({ ...(data.user), password: password, addedBy: data.addedBy });
      setShowUserDetailsModal(true);
    } catch (err) {
      console.error(err);
      setError(err.error || 'Registration failed.');
    }
  };

  return (
    <>
      {showUserDetailsModal && newUserDetails && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
          <div className="bg-white p-6 rounded shadow-lg w-full max-w-md">
            <h2 className="text-2xl font-bold mb-4">Welcome to Shri Sai Entperirses!</h2>
            <div className="mb-4">
              <p><strong>Name:</strong> {newUserDetails.name}</p>
              <p><strong>Mobile:</strong> {newUserDetails.mobile}</p>
              <p><strong>Email:</strong> {newUserDetails.email ?? "-"}</p>
              <p><strong>Password:</strong> {newUserDetails.password}</p>
              <p><strong>Referral Code:</strong> {newUserDetails.referralCode}</p>
              <p><strong>Registration Date:</strong> {new Date(newUserDetails.registeredDate).toLocaleDateString()}</p>
              <p><strong>Plan:</strong> {newUserDetails.plan ?? "-"}</p>
              <p><strong>Sponsor:</strong> {newUserDetails.addedBy.name}</p>
            </div>
            <div className="flex justify-end">
              <button
                onClick={() => {
                  setShowUserDetailsModal(false);
                  navigate('/login');
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <div>

        <div className="flex justify-center items-center min-h-screen bg-gray-100">
          <form
            onSubmit={handleRegister}
            className="bg-white p-8 rounded-lg shadow-md w-full max-w-md"
          >
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Create Account</h2>
            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 mb-4"
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 mb-4"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 mb-4"
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 mb-4"
            />
            <input
              type="text"
              placeholder="ReferralCode"
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 mb-4"
            />
            <input
              type="number"
              placeholder="Mobile No."
              value={mobile}
              onChange={(e) => setMobileNo(e.target.value)}
              className="w-full border border-gray-300 rounded-lg px-4 py-2 mb-4"
            />
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg w-full hover:bg-blue-600"
            >
              Register
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
