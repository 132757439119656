import { createContext, useState, useEffect, useContext } from "react";
import axios from '../utils/api';
import { useAuth } from "../hooks/AuthProvider";
import { useDashboard } from "../App";
const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [latestUnread, setLatestUnread] = useState([]);
    const {token} = useAuth();
    const {plan,network,earnings,commission,isApproved,commissionHistory } = useDashboard();
    useEffect(() => {
        if (token)
            fetchNotifications();
        else
            setNotifications([]);
    }, [token,plan,network,earnings,commission,isApproved,commissionHistory ]);

    const fetchNotifications = async () => {
        try {
            if(!token)
                return;
            const res = await axios.get("/notifications", {
                headers: { Authorization: `Bearer ${token}` }
            });
            const data = res.data;
            setNotifications(data);
            setUnreadCount(data.filter(n => n.status === "Unread" || n.status === "Unsent").length);
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    };
    const fetchLatestUnread = async () => {
        const { data } = await axios.get("/notifications/latest-unread", { headers: { Authorization: `Bearer ${token}` } });
        setLatestUnread(data);
        setUnreadCount(data.length);
    };

    const markAllAsRead = async () => {
        await axios.put("/notifications/mark-all-read", {}, { headers: { Authorization: `Bearer ${token}` } });
        fetchLatestUnread();
        fetchNotifications();
    };

    const markAsRead = async (id) => {
        try {
            await axios.put(`/notifications/mark-read/${id}`, {}, {

                headers: { Authorization: `Bearer ${token}` },


            });
            fetchLatestUnread();
            setNotifications(prev =>
                prev.map(n => (n._id === id ? { ...n, status: "Read" } : n))
            );
            setUnreadCount(prev => prev - 1);
        } catch (error) {
            console.error("Error marking notification as read:", error);
        }
    };

    return (
        <NotificationContext.Provider value={{ notifications, unreadCount, markAsRead, fetchNotifications, fetchLatestUnread, markAllAsRead, latestUnread }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotifications = () => useContext(NotificationContext);
