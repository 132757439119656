import React from 'react';
import { useAuth } from '../hooks/AuthProvider';

const PrivateComponent = ({ showOnLogout, children }) => {
    const {token} = useAuth();
  // token is truthy and showonlogout is true

  // token is null/false if user has not logged in
  // if showOnLogout is true then component is visible
  if(showOnLogout){
    return token ?  <></> : children;  // token is true then the component is not shown
  }
//   else the component is visible when user has logged in
  return token  ? children : <></>; // token is true then the component is shown
  // short cut
//   return Boolean(token) ^ Boolean(showOnLogout)  ? children : <></>;

};

export default PrivateComponent;
