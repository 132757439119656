import React, { useEffect, useState } from "react";
import { useNotifications } from "../context/NotificationContext";

const NotificationsPage = () => {
  const { notifications, markAllAsRead, fetchNotifications, markAsRead } = useNotifications();
  const [showUnread, setShowUnread] = useState(false);

  useEffect(() => {
    fetchNotifications();
  }, []);

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-bold mb-4">Notifications</h2>

      <div className="flex justify-between items-center mb-4">
        <button onClick={markAllAsRead} className="bg-blue-500 text-white px-4 py-2 rounded">Mark All as Read</button>
        <button onClick={() => setShowUnread(!showUnread)} className="bg-gray-500 text-white px-4 py-2 rounded">
          {showUnread ? "Show All" : "Show Unread Only"}
        </button>
      </div>

      <div className="space-y-4">
        {notifications
          .filter(notification => !showUnread || notification.status === "Unread" || notification.status === "Unsent")
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .map(notification => (
            <div key={notification._id} className={`p-4 border rounded-md ${notification.status === "Unread"||notification.status === "Unsent" ? "bg-gray-100" : ""}`}>
              <p className="font-semibold">{notification.title}</p>
              <p className="text-sm">{notification.body}</p>
              <p className="text-xs text-gray-500">{new Date(notification.createdAt).toLocaleString()}</p>
              {(notification.status === "Unread" || notification.status === "Unsent" ) && (
              <button onClick={() => markAsRead(notification._id)} className="text-blue-500 text-xs">
                Mark as Read
              </button>
            )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default NotificationsPage;
