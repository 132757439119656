import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-gray-700 mb-2">
        Effective Date: 2025-02-28
      </p>
      <p className="text-gray-700 mb-4">
        Shri Sai Enterprises ("Company," "we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you visit our website and use our services.
      </p>

      <h2 className="text-xl font-semibold mb-1">1. Information We Collect</h2>
      <p className="text-gray-700 mb-2">
        We may collect the following information:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>Personal details (Name, Address, Phone Number, Email) when you register or place an order.</li>
        <li>Payment details when making transactions through payment gateways.</li>
        <li>Usage data such as IP address, browser type, and browsing behavior.</li>
      </ul>

      <h2 className="text-xl font-semibold mb-1">2. How We Use Your Information</h2>
      <p className="text-gray-700 mb-4">
        We use your information for:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>Processing orders and payments.</li>
        <li>Providing customer support.</li>
        <li>Improving our services and website experience.</li>
        <li>Communicating updates, offers, and company news.</li>
      </ul>

      <h2 className="text-xl font-semibold mb-1">3. Data Protection & Security</h2>
      <p className="text-gray-700 mb-4">
        We take appropriate measures to protect your personal data from unauthorized access or disclosure. However, online data transmission is not 100% secure, and we cannot guarantee absolute security.
      </p>

      <h2 className="text-xl font-semibold mb-1">4. Sharing Your Information</h2>
      <p className="text-gray-700 mb-4">
        We do not sell or rent your personal data. However, we may share it with:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>Payment gateways for transaction processing.</li>
        <li>Legal authorities if required by law.</li>
      </ul>

      <h2 className="text-xl font-semibold mb-1">5. Your Rights</h2>
      <p className="text-gray-700 mb-4">
        You can:
      </p>
      <ul className="list-disc list-inside text-gray-700 mb-4">
        <li>Request access, correction, or deletion of your personal information.</li>
        <li>Opt out of marketing emails anytime.</li>
      </ul>
      <p className="text-gray-700 mb-4">
        For any privacy concerns, contact us at 8263026695/shreesaienterprises0924@gmail.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;