import React, { useEffect, useState } from 'react';
import axios from '../utils/api';
import { Link } from 'react-router-dom';
import { useAuth } from '../hooks/AuthProvider';
import AddUser from '../components/AddUser';
import DepositReturnRequest from '../components/DepositReturnRequest';//new one
import { useDashboard } from '../App';//IMPORT TO USE CONTEXT
import ImagePreviewModal from '../components/ImagePreviewModal';
import CommissionRequestModal from '../components/CommissionRequestModal';
import CommissionHistoryModal from '../components/CommissionHistoryModal';
import AllMemberCommissions from '../components/AllMemberCommissions';
import { useNotifications } from '../context/NotificationContext';
import FileInput from '../components/basic/FileInput';

const Dashboard = () => {
    const user = useAuth();
    const { plan, network, earnings, commission, updateDashboardData, isApproved } = useDashboard();

    const {fetchNotifications} = useNotifications();
    const [isCottonRequestModalVisible, setIsCottonRequestModalVisible] = useState(false);
    const [isCommissionRequestModalVisible, setIsCommissionRequestModalVisible] = useState(false);
    const [cottonQuantity, setCottonQuantity] = useState('');
    const [cottonAddress, setCottonAddress] = useState('');

    const [paymentProof, setPaymentProof] = useState(null); // New state for image
    const [cottonOrders, setCottonOrders] = useState([]);
    const [networkCottonOrders, setNetworkCottonOrders] = useState([]);
    const [isEarningsPopupVisible, setIsEarningsPopupVisible] = useState(false);
    const [selectedOrderForReturn, setSelectedOrderForReturn] = useState(null);
    const [isDepositReturnModalVisible, setIsDepositReturnModalVisible] = useState(false);
    const [isMemberModalVisible, setIsMemberModalVisible] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null); //for cotton History image
    const [isImageModalVisible, setIsImageModalVisible] = useState(false); // For image preview
    const [newEarnings, setNewEarnings] = useState(0);

    const [isCottonHistoryPopupVisible, setIsCottonHistoryPopupVisible] = useState(false); // State for history popup
    const [isCommissionHistoryPopupVisible, setIsCommissionHistoryPopupVisible] = useState(false); // State for history popup
    const cottonPricePerKg = 400; // Define the cotton price
    const [selectedOrder, setSelectedOrder] = useState(null);  //new one
    const [isApproveModalVisible, setIsApproveModalVisible] = useState(false);

    const fetchCottonOrders = async () => {
        try {
            const { data } = await axios.get('/member/cotton/orders', {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            setCottonOrders(data);
        } catch (error) {
            console.error('Failed to fetch cotton orders:', error);
        }
    };

    const fetchNetworkCottonOrders = async () => {
        try {
            const { data } = await axios.get('/member/cotton/network-orders', {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            setNetworkCottonOrders(data);
        } catch (error) {
            console.error('Failed to fetch network cotton orders:', error);
        }
    };
    useEffect(() => {
        fetchCottonOrders();
        if (user.isHead) {
            fetchNetworkCottonOrders();
        }
    }, [user.isHead, user.token]);

    const handleOpenReturnModal = (order) => {
        setSelectedOrderForReturn(order);
        setIsDepositReturnModalVisible(true);
    };

    const handleCloseReturnModal = () => {
        setSelectedOrderForReturn(null);
        setIsDepositReturnModalVisible(false);
    };

    const handleAddEarnings = async () => {
        try {
            const { data } = await axios.post(
                '/member/earnings/add',
                { earning: newEarnings },
                { headers: { Authorization: `Bearer ${user.token}` } }
            );
            updateDashboardData();// Update dashboard after success
            setIsEarningsPopupVisible(false); // Close popup
            fetchNotifications();
        } catch (error) {
            console.error('Failed to add earnings:', error);
            alert('An error occurred while adding earnings. Please try again.');
        }
    };
    const handleViewMember = (member) => {
        setSelectedMember(member);
        setIsMemberModalVisible(true);
    };
    const handleImageChange = (e) => {
        setPaymentProof(e.target.files[0]); // Store the file object
    };
    const handleRequestCotton = async (e) => {
        e.preventDefault();
        const formData = new FormData(); // Use FormData for file uploads
        formData.append('quantity', cottonQuantity);  //verify if not getting that correct
        formData.append('address', cottonAddress);
        if (paymentProof) {
            formData.append('paymentProof', paymentProof);  //verify if not getting that correct
        }
        try {
            await axios.post(
                '/member/cotton/request',
                formData,  // Send the FormData object
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                        'Content-Type': 'multipart/form-data' // Important for file uploads!
                    }
                }
            );
            setIsCottonRequestModalVisible(false);
            setCottonQuantity('');
            setPaymentProof(null);  // Reset file input
            fetchCottonOrders();
            fetchNotifications();
            alert('Cotton requested successfully! Notification sent.');

        } catch (error) {
            console.error('Failed to request cotton:', error);
            console.log(error.response.data) // Log the actual error
            alert(`Failed to request cotton: ${error.response.data.error || error.message}`);
        }
    };

    const handleUpdateCottonOrderStatus = async (orderId, status, cottonReturnQuantity = 0, rejectionReason = '') => {
        try {
            let url = `/member/cotton/orders/${orderId}`
            let payLoad = { status, rejectionReason }
            await axios.put(
                url,
                payLoad,
                { headers: { Authorization: `Bearer ${user.token}` } }
            );

            fetchNetworkCottonOrders(); // Refresh network orders
            fetchCottonOrders();       //refetch member orders
            setSelectedOrderForReturn(null); //Reset for return
            setIsApproveModalVisible(false); //Close that popup
            fetchNotifications();
            // TODO: Send notification on web and to WhatsApp using existing backend notification system
            alert(`Cotton order ${status}! Notification sent.`);  //temp. replace with notification logic
        } catch (error) {
            console.error('Failed to update cotton order status:', error);
            alert('Failed to update cotton order status. Please try again.');
        }
    };

    const handleUpdateCottonReturnStatus = async (orderId, status, cottonReturnQuantity = 0, rejectionReason = '', formData) => {
        try {

            if (!formData) {
                formData = new FormData();
                formData.append('status', status);
                formData.append('cottonReturnQuantity', cottonReturnQuantity);
                if (rejectionReason) {
                    formData.append('rejectionReason', rejectionReason);
                }
            }

            let url = `/member/cotton/orders/${orderId}/return`
            await axios.put(
                url,
                formData,
                { headers: { Authorization: `Bearer ${user.token}`, 'Content-Type': 'multipart/form-data' } }
            );

            fetchNetworkCottonOrders(); // Refresh network orders
            fetchCottonOrders();       //refetch member orders
            setSelectedOrderForReturn(null); //Reset for return
            setIsDepositReturnModalVisible(false); //Close that popup
            fetchNotifications();
            // TODO: Send notification on web and to WhatsApp using existing backend notification system
            alert(`Cotton order ${status}! Notification sent.`);  //temp. replace with notification logic
        } catch (error) {
            console.error('Failed to request return:', error);
            alert('Failed to request return. Please try again.');
        }
    };

    const handleRequestDepositReturn = async (orderId) => {
        try {
            await axios.post(
                `/member/cotton/orders/${orderId}/return`,
                {},
                { headers: { Authorization: `Bearer ${user.token}` } }
            );

            fetchNetworkCottonOrders(); // Refresh network orders
            fetchCottonOrders();
            fetchNotifications();
        } catch (error) {
            console.error('Failed to request return:', error);
            alert('Failed to request return. Please try again.');
        }
    };
    const handleApproveOrderWithImage = (order) => {
        setSelectedOrder(order); // Store the selected order
        setIsApproveModalVisible(true); //approve modal true Show your modal/popup
    }
    const handleCloseApproveModal = () => {
        setSelectedOrder(null);
        setIsApproveModalVisible(false);
    }
    const handleOpenImageModal = (image) => {
        setSelectedImage(image);    // Function for image 
        setIsImageModalVisible(true); // Function for image 
    }
    const handleCloseImageModal = () => {   // Function for image 
        setSelectedImage(null)       // Function for image 
        setIsImageModalVisible(false);   // Function for image 
    }

    if(!isApproved && !user.isHead){
        return (
            <h1 className="mr-auto ml-auto text-2xl font-bold text-gray-800 mb-[20vw] mt-[20vw]">Admin has not yet Approved. Please Complete your profile and then you can enroll in a Plan. <Link to={"/profile"} className="text-blue-600 mt-2">Click Here to Complete Profile</Link></h1>
        )
    }

    return (
        <div>
            {network===null || network===undefined ? (
                <p>Loading dashboard data...</p>
            ) : (
                <div className="bg-gray-100 min-h-screen py-8 px-4">
                    <h1 className="text-3xl font-bold text-gray-800 mb-6">Dashboard</h1>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        {!user.isHead &&
                            <div className="bg-white shadow-md rounded-lg p-6">
                                <h2 className="text-xl font-bold text-gray-800">Your Plan</h2>
                                {isApproved ? <><p className="text-gray-600 mt-2 notranslate">{plan || 'No Plan Selected'}</p>
                                    {plan!=='Gold' &&
                                     <Link to={"/plans"} >
                                        {plan ? (
                                        <button className="text-blue-500 mt-2">Upgrade Plan</button>
                                    ) : (
                                        <button className="text-blue-500 mt-2">Enroll in Plan</button>
                                    )}
                                    </Link>
                                    }
                                </> : <Link to={"/profile"} className="text-gray-600 mt-2">Admin has not yet Approved. Please Complete your profile and then you can enroll in a Plan.</Link>
                                }
                            </div>
                        }
                        <div className="bg-white shadow-md rounded-lg p-6">
                            <h2 className="text-xl font-bold text-gray-800">Earnings</h2>
                            <p className="text-gray-600 mt-2">₹{earnings}</p>

                        </div>
                        <div className="bg-white shadow-md rounded-lg p-6">
                            <h2 className="text-xl font-bold text-gray-800">Commision tracking</h2>
                            <p className="text-gray-600 mt-2">₹{commission}</p>
                            {isApproved && plan && plan !== "Bronze" && <>
                                <button
                                    onClick={() => setIsCommissionRequestModalVisible(true)}
                                    className="bg-green-500 text-white px-4 py-2 rounded mt-4"
                                >
                                    Request Commission Payout
                                </button>
                                <button
                                    onClick={() => setIsCommissionHistoryPopupVisible(true)}
                                    className="bg-blue-500 text-white px-4 py-2 rounded mt-4 ml-2"
                                >
                                    View Commission History
                                </button>
                            </>}
                        </div>
                        <div className="bg-white shadow-md rounded-lg p-6">
                            <h2 className="text-xl font-bold text-gray-800">Network Size</h2>
                            <p className="text-gray-600 mt-2">{network.length} members</p>
                        </div>
                        {!user.isHead && <div className="bg-white shadow-md rounded-lg p-6">
                            <h2 className="text-xl font-bold text-gray-800">Request Cotton</h2>
                            {plan ? (
                                <>
                                    <button
                                        onClick={() => setIsCottonRequestModalVisible(true)}
                                        className="bg-green-500 text-white px-4 py-2 rounded mt-4"
                                    >
                                        Request Cotton
                                    </button>
                                    <button
                                        onClick={() => setIsCottonHistoryPopupVisible(true)}
                                        className="bg-blue-500 text-white px-4 py-2 rounded mt-4 ml-2"
                                    >
                                        View Cotton History
                                    </button>
                                </>
                            ) : (
                                <p className="text-red-500 mt-2">Enroll in a plan to request cotton.</p>
                            )}
                        </div>}
                    </div>

                    {!user.isHead &&
                        <AddUser />}

                    {/* Start cotton order status for Head */}
                    {user.isHead && networkCottonOrders.length > 0 && (
                        <div className="mt-8">
                            <h2 className="text-2xl font-bold text-gray-800 mb-4">Network Cotton Requests</h2>
                            <div className="bg-white shadow-md rounded-lg p-6 overflow-x-auto">
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr className="bg-gray-200">
                                            <th className="px-4 py-2 text-left">Member Name</th>
                                            <th className="px-4 py-2 text-left">Quantity</th>
                                            <th className="px-4 py-2 text-left">Return Amount</th>
                                            <th className="px-4 py-2 text-left">Request Date</th>
                                            <th className="px-4 py-2 text-left">Order Status</th>
                                            <th className="px-4 py-2 text-left">Deposit Amount</th>
                                            <th className="px-4 py-2 text-left">Return Status</th>
                                            <th className="px-4 py-2 text-left">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {networkCottonOrders.map((order) => (
                                            <tr key={order._id} className="border-b">
                                                <td className="px-4 py-2">{order.member.name}</td>
                                                <td className="px-4 py-2">{order.quantity}</td>
                                                <td className="px-4 py-2">{order.depositReturnAmount}</td>
                                                <td className="px-4 py-2">{new Date(order.requestDate).toLocaleDateString()}</td>
                                                <td className="px-4 py-2">Order {order.status}</td>
                                                <td className="px-4 py-2">{order.quantity * 400}rs</td>
                                                <td className="px-4 py-2">{order.depositReturnStatus ? ("Return ₹ " + order.depositReturnStatus) : 'Not Requested'}</td>
                                                <td className="px-4 py-2">
                                                    {order.status === 'Requested' && (
                                                        <>

                                                            <button
                                                                onClick={() => handleApproveOrderWithImage(order)}  //Approve with image
                                                                className="bg-green-500 text-white px-3 py-1 rounded mr-2"
                                                            >
                                                                Approve/View
                                                            </button>
                                                            <button
                                                                onClick={() => {
                                                                    const reason = prompt("Enter rejection reason:");
                                                                    if (reason !== null) {
                                                                        handleUpdateCottonOrderStatus(order._id, 'Rejected', 0, reason);
                                                                    }
                                                                }}
                                                                className="bg-red-500 text-white px-3 py-1 rounded"
                                                            >
                                                                Reject
                                                            </button>
                                                        </>
                                                    )}

                                                    {order.status === 'Approved' && (
                                                        <>
                                                            <button
                                                                onClick={() => handleUpdateCottonOrderStatus(order._id, 'Delivered')}
                                                                className="bg-blue-500 text-white px-3 py-1 rounded"
                                                            >
                                                                Mark as Delivered
                                                            </button>
                                                        </>
                                                    )}
                                                    {order.depositReturnStatus === 'Requested' && (
                                                        <>
                                                            {/*Admin View to return*/}
                                                            <button
                                                                onClick={() => handleOpenReturnModal(order)}
                                                                className="bg-green-500 text-white px-3 py-1 rounded mr-2"
                                                            >
                                                                return amount
                                                            </button>
                                                            <button
                                                                onClick={() => {
                                                                    const reason = prompt("Enter rejection reason:");
                                                                    if (reason !== null) {
                                                                        handleUpdateCottonReturnStatus(order._id, 'Rejected', 0, reason);
                                                                    }
                                                                }}
                                                                className="bg-red-500 text-white px-3 py-1 rounded"
                                                            >
                                                                Reject
                                                            </button>
                                                        </>
                                                    )}

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    {
                        user.isHead && <AllMemberCommissions />
                    }
                    {/* End cotton order status for Head */}

                    {isEarningsPopupVisible && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
                            <div className="bg-white p-6 rounded shadow-lg w-96">
                                <h2 className="text-2xl font-bold mb-4">Add Earnings</h2>
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Enter Earnings</label>
                                    <input
                                        type="number"
                                        value={newEarnings}
                                        onChange={(e) => setNewEarnings(e.target.value)}
                                        className="block w-full mt-1 p-2 border rounded"
                                        required
                                    />
                                </div>
                                <div className="flex justify-end mt-6">
                                    <button
                                        onClick={() => setIsEarningsPopupVisible(false)}
                                        className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleAddEarnings}
                                        className="bg-blue-500 text-white px-4 py-2 rounded"
                                    >
                                        Add Earnings
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* cotton modal */}
                    {isCottonRequestModalVisible && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
                            <div className="bg-white p-6 rounded shadow-lg w-96">
                                <h2 className="text-2xl font-bold mb-4">Request Cotton</h2>
                                <form onSubmit={handleRequestCotton}>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">Quantity (in kg)</label>
                                        <input
                                            type="number"
                                            value={cottonQuantity}
                                            onChange={(e) => setCottonQuantity(e.target.value)}
                                            className="block w-full mt-1 p-2 border rounded"
                                            required
                                            min="1"
                                        />
                                        <p className="text-gray-500 mt-2">Price: ₹{cottonPricePerKg}/kg</p>
                                        <p className="text-gray-600">Total price: ₹{cottonQuantity * cottonPricePerKg}</p>
                                        <label className="block text-sm font-medium text-gray-700">Address</label>
                                        <input
                                            type="text"
                                            value={cottonAddress}
                                            placeholder="Add your Address district, state,pincode"
                                            onChange={(e) => setCottonAddress(e.target.value)}
                                            className="block w-full mt-1 p-2 border rounded"
                                            required
                                            min="1"
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-medium text-gray-700">Payment Proof</label>
                                        <FileInput
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            className="block w-full mt-1"
                                        />
                                    </div>
                                    <div className="flex justify-end">
                                        <button
                                            type="button"
                                            onClick={() => setIsCottonRequestModalVisible(false)}
                                            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="submit"
                                            className="bg-blue-500 text-white px-4 py-2 rounded"
                                        >
                                            Request
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}

                    {/* commission payout modal */}
                    {isCommissionRequestModalVisible && <CommissionRequestModal onClose={() => setIsCommissionRequestModalVisible(false)} />}

                    {/* Admin Approval Modal */}
                    {isApproveModalVisible && selectedOrder && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
                            <div className="bg-white p-6 rounded shadow-lg w-96">
                                <h2 className="text-2xl font-bold mb-4">Approve Cotton Request</h2>
                                <p>Member: {selectedOrder.member.name}</p>
                                <p>Quantity: {selectedOrder.quantity} kg</p>
                                <p>Address: {selectedOrder.address} </p>
                                <p>Mobile: {selectedOrder.member.mobile} </p>
                                {selectedOrder.paymentProof && (
                                    <img src={selectedOrder.paymentProof} alt="Payment Proof" className="max-w-full h-auto mb-4" />
                                )}
                                <div className="flex justify-end">
                                    <button
                                        onClick={() => handleUpdateCottonOrderStatus(selectedOrder._id, 'Approved')}
                                        className="bg-green-500 text-white px-4 py-2 rounded mr-2"
                                    >
                                        Approve
                                    </button>
                                    <button
                                        onClick={handleCloseApproveModal}
                                        className="bg-gray-500 text-white px-4 py-2 rounded"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}


                    {/* Member's Cotton Order History Popup */}
                    {isCottonHistoryPopupVisible && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-10 ">
                            <div className="bg-white p-6 rounded shadow-lg w-full max-w-3xl">
                                <h2 className="text-2xl font-bold mb-4">Your Cotton Order History</h2>
                                <div className="h-[70vh] overflow-auto">
                                    <table className="table-auto w-full">
                                        <thead>
                                            <tr className="bg-gray-200">
                                                <th className="px-4 py-2 text-left">Quantity</th>
                                                <th className="px-4 py-2 text-left">Rate</th>
                                                <th className="px-4 py-2 text-left">Request Date</th>
                                                <th className="px-4 py-2 text-left">Status</th>
                                                <th className="px-4 py-2 text-left">Reason</th>
                                                <th className="px-4 py-2 text-left">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cottonOrders.map((order) => (
                                                <tr key={order._id} className="border-b">
                                                    <td className="px-4 py-2">{order.quantity} kg</td>
                                                    <td className="px-4 py-2">{order.quantity * 400} kg</td>
                                                    <td className="px-4 py-2">{new Date(order.requestDate).toLocaleDateString()}</td>
                                                    <td className="px-4 py-2">{order.status}</td>
                                                    <td className="px-4 py-2">{order.rejectionReason || ' - '}</td>

                                                    <td className="px-4 py-2">
                                                        {/* Add image */}
                                                        {order.adminProvidedImage && (
                                                            <button
                                                                onClick={() => handleOpenImageModal(order.adminProvidedImage)}
                                                                className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                                                            >
                                                                View Image
                                                            </button>
                                                        )}
                                                        {order.status === 'Delivered' && order.depositReturnRequested === false && (
                                                            <button
                                                                onClick={() => handleRequestDepositReturn(order._id)}
                                                                className="bg-blue-500 text-white px-4 py-2 rounded"
                                                            >
                                                                Request Cotton Deposit
                                                            </button>
                                                        )}
                                                        {order.status === 'Delivered' && order.depositReturnStatus !== null && (
                                                            <span className="text-gray-600">Return Status: {order.depositReturnStatus}</span>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="flex justify-end mt-6">
                                    <button
                                        onClick={() => setIsCottonHistoryPopupVisible(false)}
                                        className="bg-gray-500 text-white px-4 py-2 rounded"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    {isCommissionHistoryPopupVisible && <CommissionHistoryModal
                        handleOpenImageModal={handleOpenImageModal}
                        onClose={() => setIsCommissionHistoryPopupVisible(false)} />}

                    {isDepositReturnModalVisible && selectedOrderForReturn && (
                        <DepositReturnRequest
                            order={selectedOrderForReturn}
                            onClose={handleCloseReturnModal}
                            onUpdateStatus={handleUpdateCottonReturnStatus}
                        />
                    )}
                    {isMemberModalVisible && selectedMember && (
                        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
                            <div className="bg-white p-6 rounded shadow-lg w-96">
                                <h2 className="text-2xl font-bold mb-4">{selectedMember.name} Details</h2>
                                <p><strong>Email:</strong> {selectedMember.email}</p>
                                <p><strong>Mobile:</strong> {selectedMember.mobile}</p>
                                <p className='notranslate'><strong>Plan:</strong> {selectedMember.plan || 'N/A'}</p>
                                <p><strong>Deposit:</strong> ₹{selectedMember.deposit || 400}</p>
                                <p><strong>Return Amount:</strong> ₹{selectedMember.returnAmount || 300}</p>
                                <p><strong>Total Balance:</strong> ₹{(selectedMember.deposit || 400) - (selectedMember.returnAmount || 300)}</p>
                                <div className="flex justify-end mt-6">
                                    <button
                                        onClick={() => setIsMemberModalVisible(false)}
                                        className="bg-gray-500 text-white px-4 py-2 rounded"
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {/* Image Preview Modal */}
            {isImageModalVisible && (
                <ImagePreviewModal
                    imageUrl={selectedImage}
                    onClose={handleCloseImageModal}
                />
            )}
        </div>


    );
};

export default Dashboard;
