import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PrivateComponent from './PrivateComponent';
import { useAuth } from '../hooks/AuthProvider';
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { BellIcon } from "@heroicons/react/24/outline";
import { useNotifications } from "../context/NotificationContext";
const Navbar = () => {
  const { unreadCount, markAsRead, fetchLatestUnread, latestUnread } = useNotifications();
  const user = useAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const menuRef = useRef(null);
  const dropdownRef = useRef(null);
  
  const location = useLocation();
   
  const navigate = useNavigate();

  useEffect(() => {
    if (user.token)
      fetchLatestUnread();
  }, [user.token]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !menuRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const addGoogleTranslateScript = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      document.body.appendChild(script);
    };

    const initializeGoogleTranslate = () => {
      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement({
          pageLanguage: 'en',
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE
        }, 'google_translate_element');
      }
    };

    if (!document.querySelector('script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]')) {
      initializeGoogleTranslate();
      addGoogleTranslateScript();
    }
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleNavLinkClick = (path) => {
    closeMenu();
    navigate(path);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <nav className="h-15 bg-gradient-to-r from-purple-500 to-pink-500 text-yellow-200 font-semibold ">
      <div className="container mx-auto flex justify-between items-center relative">
        {/* Burger Icon (Mobile) */}
        <div className="md:hidden order-last">
          <button onClick={toggleMenu} className="focus:outline-none">
            {isMenuOpen ? (
              <XMarkIcon className="h-6 w-6" />
            ) : (
              <Bars3Icon className="h-6 w-6" />
            )}
          </button>
        </div>

        {/* Logo */}
        <h1 className="text-3xl ml-11 font-semibold ">
          <Link to="/" className="order-1" onClick={closeMenu}>Shri Sai Enterprises</Link>
        </h1>
         {/* Google Translate - always visible */}
         <div id="google_translate_element" className="order-2 mt-2 md:mt-0"></div>

        {/* Navigation Links */}
        <div ref={menuRef} className={`md:flex gap-10 items-center absolute md:relative top-[60%] md:top-auto right-0 w-64 md:w-auto transform transition-transform origin-top-right duration-200 ease-in-out ${isMenuOpen ? 'bg-gradient-to-r from-purple-500 to-pink-500 text-yellow-200 font-semibold' : 'scale-0'} md:scale-[100%] md:translate-x-0 md:opacity-100 md:visible p-4 md:p-0 z-10 overflow-x-hidden `} >
          
          <Link to="/"  className={`hover:underline block md:inline ${location.pathname === "/" ? "bg-pink-800 rounded-xl p-2": ""}` } onClick={() => handleNavLinkClick("/")}>
            Home
          </Link>
          <Link to="/about" className={`hover:underline block md:inline ${location.pathname==="/about" ? "bg-pink-800 rounded-xl p-2": ""}` } onClick={() => handleNavLinkClick("/about")}>
            About
          </Link>
          <Link to="/plans" className={`hover:underline block md:inline ${location.pathname==="/plans" ? "bg-pink-800 rounded-xl p-2": ""}`} onClick={() => handleNavLinkClick("/plans")}>
            Plans
          </Link>
          <PrivateComponent>
            <Link to="/dashboard" className={`hover:underline block md:inline ${location.pathname==="/dashboard" ? "bg-pink-800 rounded-xl p-2": ""}`} onClick={() => handleNavLinkClick("/dashboard")}>
              Dashboard
            </Link>
          </PrivateComponent>
          <Link to="/faq" className={`hover:underline block md:inline ${location.pathname==="/faq" ? "bg-pink-800 rounded-xl p-2": ""}`} onClick={() => handleNavLinkClick("/faq")}>
            FAQ
          </Link>
          <PrivateComponent showOnLogout={false}>
            <Link to="/profile" className={`hover:underline block md:inline ${location.pathname==="/profile" ? "bg-pink-800 rounded-xl p-2": ""}`} onClick={() => handleNavLinkClick("/profile")}>
              Profile
            </Link>
          </PrivateComponent>
          <PrivateComponent showOnLogout={true}>
            <Link to="/login" className={`hover:underline block md:inline ${location.pathname==="/login" ? "bg-pink-800 rounded-xl p-2": ""}`} onClick={() => handleNavLinkClick("/login")}>
              Login
            </Link>
          </PrivateComponent>
          <PrivateComponent showOnLogout={false}>
            <span onClick={() => { user.logOut(); closeMenu(); }} className="hover:underline cursor-pointer block md:inline">
              Logout
            </span>
          </PrivateComponent>
        </div>
 
 {/* Notification Bell */}
 <div className="relative" ref={dropdownRef}>
          <button onClick={() => setOpen(!open)} className="relative">
            <BellIcon className="h-6 w-6" />
            {unreadCount > 0 && (
              <span className="absolute top-0 right-0 bg-red-500 text-white text-xs px-1.5 rounded-full">
                {unreadCount}
              </span>
            )}
          </button>

          {open && (
            <div className="absolute right-0 mt-2 w-72 bg-white border rounded shadow-md p-2 text-black overflow-y-auto max-h-60 z-10">
              {latestUnread.length === 0 ? (
                <p className="text-gray-500">No notifications</p>
              ) : (
                latestUnread.slice(0, 5).map(notification => (
                  <div key={notification._id} className={`p-2 border-b ${notification.status === "Unread" || notification.status === "Unsent"? "bg-gray-100 text-red-700" : ""}`}>
                    <p className="font-semibold">{notification.title}</p>
                    <p className="text-sm">{notification.body}</p>
                    {(notification.status === "Unread" || notification.status === "Unsent") && (
                      <button onClick={() => markAsRead(notification._id)} className="text-blue-500 text-xs">
                        Mark as Read
                      </button>
                    )}
                  </div>
                ))
              )}
              <div className="text-center mt-2">
                <Link to="/notifications" onClick={()=>setOpen(false)} className="text-blue-500">View All Notifications</Link>
              </div>
            </div>
          )}
</div>
      </div>
    </nav>
  );
};

export default Navbar;
