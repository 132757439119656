import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Plans from './pages/Plans';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import FAQ from './pages/FAQ';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';
import Profile from './pages/Profile';
import SideNavbar from './components/SideNavbar';
import PrivateComponent from './components/PrivateComponent';
import AuthProvider, { useAuth } from './hooks/AuthProvider';
import NetworkPage from './pages/NetworkPage';
import AddUser from './components/AddUser';
import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from './utils/api';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import { NotificationProvider } from './context/NotificationContext';
import NotificationsPage from './components/NotificationsPage';
import ContactUs from './components/ContactUS';
import ChangePassword from './components/ChangePassword';
// Create a context for dashboard data
const DashboardContext = createContext();

// Create a provider component
const DashboardProvider = ({ children }) => {
    const [earnings, setEarnings] = useState(0);
    const [commission, setCommission] = useState(0);
    const [network, setNetwork] = useState([]);
    const [commissionHistory, setCommissionHistory] = useState([]);
    const [plan, setPlan] = useState(null);
    const { token } = useAuth(); // Get token from AuthProvider
    const [isApproved, setIsApproved] = useState(false);

    const fetchDashboard = async () => {
        try {
            const { data } = await axios.get('/member/dashboard', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setPlan(data.plan);
            setNetwork(Array.isArray(data.network) ? data.network : []);
            setEarnings(data.earnings);
            setCommission(data.commission);
            setIsApproved(data.isApproved);
            setCommissionHistory(data.commissionHistory);
        } catch (error) {
            console.error('Failed to fetch dashboard data:', error);
        } 
    };

    useEffect(() => {
        if (token) { // Only fetch data if a token is present
            fetchDashboard();
        }
    }, [token]);

    const updateDashboardData = () => { // Function to trigger data refresh
        fetchDashboard();
    };
    const dashboardContextValue = {
        plan,
        network,
        earnings,
        commission,
        updateDashboardData,
        isApproved,
        commissionHistory
    };

    return (
        <DashboardContext.Provider value={dashboardContextValue}>
            {children}
        </DashboardContext.Provider>
    );
};

function App() {

    return (
    <>
        <div id="loading-box" className='hidden fixed z-50 h-[100%] w-[100%] bg-[#E5EFF1]' 
        
        // style={{position:'fixed' ,zIndex:50, height:'100%',width:'100%', backdropFilter:'blur(5px)', backgroundColor: 'rgba(255, 255, 255)'}}
        
        > 
        <img className='block ml-auto mr-auto mt-[40vh] sm:mt-[25vh] h-[50vw] sm:h-[20vw]' src='/loading-icon.gif'/>
        </div>
        <Router>
            <AuthProvider>
            <DashboardProvider>
            <NotificationProvider>
                <PrivateComponent>
                    <SideNavbar />
                </PrivateComponent>
                <Navbar />
                

                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/about" element={<About />} />
                        <Route path="/plans" element={<Plans />} />
                        <Route path="/contact" element={<ContactUs />} />
                        <Route path="/change-password" element={<ChangePassword/>} />
                        <Route
                            path="/dashboard"
                            element={
                                <PrivateRoute>
                                    <Dashboard />
                                </PrivateRoute>
                            } />
                        <Route
                            path="/profile"
                            element={
                                <PrivateRoute>
                                    <Profile />
                                </PrivateRoute>
                            } />
                        <Route
                            path="/network"
                            element={
                                <PrivateRoute>
                                    <NetworkPage />
                                </PrivateRoute>
                            } />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/faq" element={<FAQ />} />
                        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
                        

                        <Route path="/notifications" element={
                            <PrivateRoute>
                                <NotificationsPage />
                            </PrivateRoute>
                        } />
                        
                        <Route path="/adduser" element={
                            <PrivateRoute>
                                <AddUser />
                            </PrivateRoute>
                        } />
                    </Routes>

                
                   
                </NotificationProvider>
                <Footer />
            </DashboardProvider>
            </AuthProvider>
        </Router>
    </>
    );
}

export default App;
export const useDashboard = () => useContext(DashboardContext); // Custom hook
