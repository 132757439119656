import React, { useState, useEffect } from "react";
import axios from "../utils/api"; // Axios instance with base URL and token setup
import { useAuth } from "../hooks/AuthProvider";
import ImagePreviewModal from "../components/ImagePreviewModal";
import FileInput from "../components/basic/FileInput";

const Profile = () => {
    const [profile, setProfile] = useState({
        name: "",
        email: "",
        mobile: "",
        plan: "",
        earnings: 0,
        image: "",
        adharNo: "",
        panCardNo: "",
        bankAccountNo: "",
        address: "",
        ifscCode: ""
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editedProfile, setEditedProfile] = useState({});
    const [newAvatar, setNewAvatar] = useState(null);
    const [idProof, setIDProof] = useState(null);
    const [panidProof, setpanIDProof] = useState(null);
    const [viewIDProof, setViewIDProof] = useState(false);
    const [viewPanIDProof, setViewPanIDProof] = useState(false);
    const user = useAuth();

    const fetchProfile = async () => {
        try {
            const { data } = await axios.get("/user/profile", {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            setProfile(data);
        } catch (error) {
            console.error("Error fetching profile:", error);
        }
    };

    useEffect(() => {
        fetchProfile();
    }, []);

    const handleEdit = () => {
        setIsEditing(true);
        setEditedProfile(profile);
    };

    const handleAvatarChange = (e) => {
        setNewAvatar(e.target.files[0]);
    };
    const handleIdProof = (e) => {
        setIDProof(e.target.files[0]);

    }
    const handlepanIdProof = (e) => {

        setpanIDProof(e.target.files[0]);
    }
    const handleSave = async () => {
        try {
            let updatedProfile = { ...editedProfile };

            if (newAvatar) {
                const formData = new FormData();
                formData.append("avatar", newAvatar);

                const uploadResponse = await axios.post("/user/upload-avatar", formData, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                updatedProfile.image = uploadResponse.data.imageUrl;
            }

            if (idProof) {
                const formData = new FormData();
                formData.append("idProof", idProof);

                const uploadResponse = await axios.post("/user/upload-idproof", formData, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                updatedProfile.idProof = uploadResponse.data.idProof;
            }

            if (panidProof) {
                const formData = new FormData();
                formData.append("panidProof", panidProof);

                const uploadResponse = await axios.post("/user/upload-panidproof", formData, {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                        "Content-Type": "multipart/form-data",
                    },
                });
                updatedProfile.panidProof = uploadResponse.data.panidProof;
            }
            const { data } = await axios.put("/user/profile", updatedProfile, {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            setProfile(data);
            setIsEditing(false);
            alert("Profile updated successfully!");
        } catch (error) {
            console.error("Error updating profile:", error);
            alert("Failed to update profile. Please try again.");
        }
    };

    return (
        <div className="bg-gray-100 min-h-screen py-8 px-4">
            <h1 className="text-3xl text-center font-bold text-gray-800 mb-6">Your Profile</h1>
            <div className="bg-white shadow-md rounded-lg p-6">
                {!isEditing ? (
                    <div className="text-center">
                        <img src={profile.image} alt="Avatar" className="w-24 h-24 rounded-full mx-auto mb-4" />
                        <div className="text-left mx-auto md:w-1/2 w-full">
                            <p className="text-lg"><strong>Name:</strong> {profile.name}</p>
                            <p className="text-lg"><strong>Email:</strong> {profile.email}</p>
                            <p className="text-lg"><strong>Mobile:</strong> {profile.mobile || "Not Provided"}</p>
                            <p className="text-lg"><strong>Address:</strong> {profile.address || "Not Provided"}</p>
                            <p className="text-lg notranslate"><strong>Plan:</strong> {profile.plan || "No Plan Selected"}</p>
                            <p className="text-lg"><strong>Earnings:</strong> ₹{profile.earnings}</p>
                            <p className="text-lg"><strong>Adhar Number:</strong> {profile.adharNo}</p>
                            <p className="text-lg"><strong>PAN Card Number:</strong> {profile.panCardNo}</p>
                            <p className="text-lg"><strong>Bank Account Number:</strong> {profile.bankAccountNo}</p>
                            <p className="text-lg"><strong>IFSC Code:</strong> {profile.ifscCode || "Not Provided"}</p>
                        </div>
                        {profile.idProof ? <button onClick={() => setViewIDProof(true)} className="block bg-blue-500 text-white px-4 py-2 rounded mt-4 mx-auto">View IDProof</button> : <p className="text-red-500">ID not uploaded</p>}
                        {profile.panidProof ? <button onClick={() => setViewPanIDProof(true)} className="block bg-blue-500 text-white px-4 py-2 rounded mt-4 mx-auto">View PanIDProof</button> : <p className="text-red-500">Pan ID not uploaded</p>}
                        {viewIDProof && (
                            <ImagePreviewModal
                                imageUrl={profile.idProof}
                                onClose={() => setViewIDProof(false)}
                            />
                        )}
                        {viewPanIDProof && (
                            <ImagePreviewModal
                                imageUrl={profile.panidProof}
                                onClose={() => setViewPanIDProof(false)}
                            />
                        )}
                        <button onClick={handleEdit} className="bg-blue-500 text-white px-4 py-2 rounded mt-4 mx-auto">Edit Profile</button>
                    </div>
                ) : (
                    <div>
                        <form className="flex justify-center">
                            <div className="mb-4 w-full md:w-2/5 flex flex-col gap-4">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Name</label>
                                    <input type="text" value={editedProfile.name} onChange={(e) => setEditedProfile({ ...editedProfile, name: e.target.value })} className="block w-full mt-1 p-2 border rounded" />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Address</label>
                                    <input type="text" value={editedProfile.address} onChange={(e) => setEditedProfile({ ...editedProfile, address: e.target.value })} className="block w-full mt-1 p-2 border rounded" />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Adhar Number</label>
                                    <input type="text" value={editedProfile.adharNo} onChange={(e) => setEditedProfile({ ...editedProfile, adharNo: e.target.value })} className="block w-full mt-1 p-2 border rounded" />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">PAN Card Number</label>
                                    <input type="text" value={editedProfile.panCardNo} onChange={(e) => setEditedProfile({ ...editedProfile, panCardNo: e.target.value })} className="block w-full mt-1 p-2 border rounded" />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Bank Account Number</label>
                                    <input type="text" value={editedProfile.bankAccountNo} onChange={(e) => setEditedProfile({ ...editedProfile, bankAccountNo: e.target.value })} className="block w-full mt-1 p-2 border rounded" />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">IFSC Code</label>
                                    <input type="text" value={editedProfile.ifscCode} onChange={(e) => setEditedProfile({ ...editedProfile, ifscCode: e.target.value })} className="block w-full mt-1 p-2 border rounded" />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Upload ID Proof</label>
                                    <FileInput onChange={handleIdProof} className="block w-full mt-1 p-2 border rounded" />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Upload Pan ID Proof</label>
                                    <FileInput onChange={handlepanIdProof} className="block w-full mt-1 p-2 border rounded" />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-sm font-medium text-gray-700">Upload Avatar</label>
                                    <FileInput onChange={handleAvatarChange} className="block w-full mt-1 p-2 border rounded" />
                                </div>
                                <div className="flex justify-center">
                                    <button type="button" onClick={() => setIsEditing(false)} className="bg-gray-500 text-white px-4 py-2 rounded mr-2">Cancel</button>
                                    <button type="button" onClick={handleSave} className="bg-blue-500 text-white px-4 py-2 rounded">Save</button>
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Profile;
