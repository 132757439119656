import React from 'react';

const ContactUs = () => {
  return (
    <div className="container mx-auto py-12 px-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-4xl font-bold mb-6 text-center text-purple-700">Contact Us</h1>
      <p className="mb-4 text-lg text-gray-700 text-center">We would love to hear from you! Please reach out to us at:</p>
      <address className="not-italic mb-6 text-center text-gray-600">
      <p className="mb-2 text-xl font-semibold text-purple-700">Address:</p>
        <p className="mb-2 text-xl font-semibold text-gray-800">Pt no 28 Rajeshree Shahu Nagar,</p>
        <p className="mb-2 text-xl font-semibold text-gray-800">Near Dynjyot School,</p>
        <p className="mb-2 text-xl font-semibold text-gray-800">Behind Suparkar Complex</p>
        <p className="mb-2 text-xl font-semibold text-gray-800">Ambad Road, Jalna 431213</p>
      </address>
      <p className="mb-4 text-lg text-gray-700 text-center">You can also contact us via email or phone:</p>
      <p className="mb-2 text-center">
        Email: <a href="mailto:info@shrisai.com" className="text-blue-500 hover:underline text-lg font-medium">shreesaienterprises0924@gmail.com</a>
      </p>
      <p className="mb-6 text-center">
        Phone: <a href="tel:+918830205144" className="text-blue-500 hover:underline text-lg font-medium">+918830205144</a>
      </p>
      <div className="flex justify-center">
        <iframe
          title="Shri Sai Enterprises Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.835434507073!2d-122.4194154846813!3d37.7749292797597!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8085809c5f0f0f0f%3A0x0!2zMzfCsDQ2JzI5LjgiTiAxMjLCsDI1JzEwLjkiVw!5e0!3m2!1sen!2sus!4v1616623141234!5m2!1sen!2sus"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;