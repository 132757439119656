import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const products = [
  { id: 1, name: "Pure Cotton Wicks", image: "/cotton3.jpeg", description: "Handmade premium quality wicks.", plans: ["Bronze", "Silver", "Gold"] , features: ['200g cotton for practice', 'Round cotton wick production']},
  { id: 2, name: "Long Cotton Wicks", image: "/cotton4.jpeg", description: "Ideal for daily puja and rituals.", plans: ["Silver", "Gold"],  features: [
    '300g cotton for practice',
    'Round & long wicks production',
    'Packaging included',
  ] },
  { id: 3, name: "Round Cotton Wicks", image: "/cotton5.jpeg", description: "Perfect God Vastra Mala for festivals.", plans: ["Gold"],  features: [
    '400g cotton for practice',
    'Round & long wicks production',
    'Vastramala production',
    'Packaging included',
  ],},
];

const Home = () => {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleViewDetails = (product) => {
    setSelectedProduct(product);
  };

  const closeModal = () => {
    setSelectedProduct(null);
  };

  return (
    <div>
      {/* Hero Section */}
      <section className="relative h-[50vh] bg-cover bg-center flex items-center justify-center text-center gap-3">
        <div className="bg-black bg-opacity-50 p-10 rounded-xl">
          <h1 className="text-4xl md:text-6xl text-white font-bold">Illuminate Your Life with Quality Cotton Wicks</h1>
          <p className="text-white text-lg mt-2">Upholding India's Rich Cultural Heritage</p>
          <button className="mt-4 px-6 py-2 text-lg bg-yellow-500 hover:bg-yellow-600 rounded-lg">Explore Our Products</button>
        </div>
      </section>

      <Carousel className="w-[500px] max-w-fit max-h-fit ml-auto mr-auto bg-gray-500 bg-opacity-50 p-10 rounded-xl gap-6" autoPlay infiniteLoop showThumbs={false} showStatus={false}>
        <img src="/cotton.jpg" alt="Cotton Wicks" className="w-3/4 max-w-4xl rounded-lg shadow-lg" />
        <img src="/cotton2.jpeg" alt="Cotton Wicks" className="w-3/4 max-w-4xl rounded-lg shadow-lg" />
      </Carousel>

      {/* Product Showcase Section */}
      <section className="py-16 px-4">
        <h2 className="text-3xl font-bold text-center mb-6">Our Products</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {products.map((product) => (
            <div key={product.id} className="transform transition-transform hover:scale-105">
              <div className="bg-white shadow-lg rounded-lg overflow-hidden">
                <img src={product.image} alt={product.name} className="w-full h-48 object-cover" />
                <div className="p-4 text-center">
                  <h3 className="text-lg font-semibold">{product.name}</h3>
                  <p className="text-gray-600">{product.description}</p>
                  <button
                    onClick={() => handleViewDetails(product)}
                    className="mt-3 bg-blue-500 hover:bg-blue-600 rounded-lg px-4 py-2 text-white"
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Modal for Product Details */}
      {selectedProduct && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-2xl font-bold mb-4">{selectedProduct.name}</h2>
            <img src={selectedProduct.image} alt={selectedProduct.name} className="w-full h-48 object-cover rounded-lg mb-4" />
            <p className="text-gray-600 mb-4">{selectedProduct.description}</p>
            <h3 className="text-lg font-semibold mb-2">Available Plans:</h3>
            <ul className="list-disc list-inside text-gray-700">
              {selectedProduct.plans.map((plan, index) => (
                <li key={index}>{plan}</li>
              ))}
            </ul>
            <h3 className="text-lg font-semibold mt-4 mb-2">Features:</h3>
            <ul className="list-disc list-inside text-gray-700">
              {selectedProduct.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <div className="flex justify-end mt-6">
              <button onClick={closeModal} className="bg-gray-500 text-white px-4 py-2 rounded">
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Why Choose Us Section */}
      <section className="py-16 bg-gray-100 text-center">
        <h2 className="text-3xl font-bold">Why Choose Us</h2>
        <div className="flex flex-wrap justify-center mt-6 gap-8">
          {[
            { text: "Quality Assurance" },
            { text: "Large Production Capacity" },
            { text: "Affordable Pricing" },
          ].map((item, index) => (
            <div key={index} className="flex items-center space-x-3">
              <FaCheckCircle className="text-green-500 text-xl" />
              <span className="text-lg font-semibold">{item.text}</span>
            </div>
          ))}
        </div>
      </section>

      {/* Join Us Section */}
      <section className="py-16 bg-gray-500 text-center text-white">
        <h2 className="text-3xl font-bold">Join Us</h2>
        <p className="mt-2">Partner with us as a distributor or retailer.</p>
        <button className="mt-4 px-6 py-2 text-lg text-black bg-yellow-500 hover:bg-yellow-600 rounded-lg">Contact Us</button>
      </section>
    </div>
  );
};

export default Home;
