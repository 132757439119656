import React, { useState } from 'react';
import axios from "../utils/api";
import { useAuth } from '../hooks/AuthProvider';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ChangePassword = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const user = useAuth();

    const changePassword = async () => {
        try {
            if (password !== confirmPassword) {
                alert('Passwords do not match');
                return;
            }

            const updatepassword = await axios.put("/user/change-password", { password, confirmPassword }, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            });

            console.log(updatepassword);
            alert("Password changed successfully! NewPassword: " + password);
        } catch (error) {
            console.error("Error changing password:", error);
            alert("Failed to change password.");
        }
    };
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="bg-white ml-auto mr-auto w-[12%] min-w-fit shadow-md rounded-lg p-6 mt-6">
            <h2 className="text-xl font-bold text-center">Change Password</h2>
            {/* <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter New Password"
                className="block ml-auto mr-auto w-fit mt-2 p-2 border rounded"
            /> */}
            <div className="relative">
                <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="block w-full mb-4 p-2 border rounded"
                />
                <div
                    className="absolute inset-y-0 right-0 pr-1 flex items-center cursor-pointer text-gray-500"
                    onClick={togglePasswordVisibility}
                >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                </div>
            </div>
            <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
                className="block ml-auto mr-auto w-fit mt-2 p-2 border rounded"
            />
            <button
                onClick={changePassword}
                className="block bg-blue-500 w-fit ml-auto mr-auto text-white px-4 py-2 rounded mt-4"
            >
                Confirm Password
            </button>
        </div>
    );
};

export default ChangePassword;
