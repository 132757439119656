import React, { useEffect, useState } from 'react';
import axios from '../utils/api';
import { useAuth } from '../hooks/AuthProvider';
import { useDashboard } from '../App'; // Import to use context
import FileInput from './basic/FileInput';

const AddUser = () => {
    const { plan, network, updateDashboardData } = useDashboard(); // Get data from context
    const [plans, setPlans] = useState([]);
    const [selectedMember, setSelectedMember] = useState(null);
    const [isMemberModalVisible, setIsMemberModalVisible] = useState(false);
    const [newUserDetails, setNewUserDetails] = useState(null);
    const [showUserDetailsModal, setShowUserDetailsModal] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [planProof, setPlanProof] = useState(null);

    const user = useAuth();
    const [newMember, setNewMember] = useState({
        name: '',
        email: '',
        password: '',
        mobile: '',
        plan: ''
    });
    const [isFormVisible, setIsFormVisible] = useState(false);

    const handleViewMember = (member) => {
        setSelectedMember(member);
        setIsMemberModalVisible(true);
    };

    const handleAddMember = async (e) => {
        e.preventDefault();
        if (newMember.email) {
            if (!validateEmail(newMember.email)) {
                setEmailError('Invalid email format');
                return;
            }
        }

        try {
            const formData = new FormData();
            // formData.append("name", newMember.name);
            // formData.append("email", newMember.email);
            // formData.append("mobile", newMember.mobile);
            // formData.append("password", newMember.password);
            // formData.append("plan", newMember.plan);

            for (let key in newMember) {
                formData.append(key, newMember[key])
            }
            formData.append("planProof", planProof);
            const { data } = await axios.post(
                '/member/add',
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${user.token}`,
                        "Content-Type": "multipart/form-data",
                    }
                }
            );
            // backend password is hashed so show the frontend data password 
            setNewUserDetails({ ...(data.user), password: newMember.password, addedBy: data.addedBy });
            setNewMember({ name: '', email: '', password: '', mobile: '', plan: '' });
            setIsFormVisible(false);
            setShowUserDetailsModal(true);
            updateDashboardData();
        } catch (error) {
            console.error('Failed to add new member:', error);
            alert(error.error);
        }
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const handlePlanProof = (e) => {
        setPlanProof(e.target.files[0]);
    };

    const fetchPlans = async () => {
        try {
            const { data } = await axios.get('/plans', {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            setPlans(data);
        } catch (error) {
            console.error('Failed to fetch plans:', error);
        }
    };

    useEffect(() => {
        fetchPlans();
    }, [user.token]);

    return (
        <>
            {showUserDetailsModal && newUserDetails && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
                    <div className="bg-white p-6 rounded shadow-lg w-full max-w-md h-15 bg-gradient-to-r from-purple-500 to-pink-500 text-yellow-200 font-semibold">
                        <h2 className="text-2xl font-bold mb-4">Welcome to Shri Sai Entperirses!</h2>
                        <div className="mb-4 text-blue-950">
                            <p><strong>Name:</strong> {newUserDetails.name}</p>
                            <p><strong>Mobile:</strong> {newUserDetails.mobile}</p>
                            <p><strong>Email:</strong> {newUserDetails.email ?? "-"}</p>
                            <p><strong>Password:</strong> {newUserDetails.password}</p>
                            <p><strong>Referral Code:</strong> {newUserDetails.referralCode}</p>
                            <p><strong>Registration Date:</strong> {new Date(newUserDetails.registeredDate).toLocaleDateString()}</p>
                            <p><strong>Plan:</strong> {newUserDetails.plan ?? "-"}</p>
                            <p><strong>Sponsor:</strong> {newUserDetails.addedBy.name}</p>
                        </div>
                        <div className="flex justify-end">
                            <button
                                onClick={() => setShowUserDetailsModal(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isMemberModalVisible && selectedMember && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
                    <div className="bg-white p-6 rounded shadow-lg w-96">
                        <h2 className="text-2xl font-bold mb-4">{selectedMember.name} Details</h2>
                        <p><strong>Email:</strong> {selectedMember.email}</p>
                        <p><strong>Mobile:</strong> {selectedMember.mobile}</p>
                        <p className="notranslate"><strong>Plan:</strong> {selectedMember.plan || 'N/A'}</p>
                        <p><strong>Address:</strong> {selectedMember.address}</p>
                        <div className="flex justify-end mt-6">
                            <button
                                onClick={() => setIsMemberModalVisible(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isFormVisible && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
                    <div className="bg-white p-6 rounded shadow-lg w-96">
                        <h2 className="text-2xl font-bold mb-4">Add New Member</h2>
                        <form onSubmit={handleAddMember}>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Name</label>
                                <input
                                    type="text"
                                    value={newMember.name}
                                    onChange={(e) => setNewMember({ ...newMember, name: e.target.value })}
                                    className="block w-full mt-1 p-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Email</label>
                                <input
                                    type="text"
                                    value={newMember.email}
                                    onChange={(e) => {
                                        setNewMember({ ...newMember, email: e.target.value });
                                        setEmailError('');
                                    }}
                                    className="block w-full mt-1 p-2 border rounded"

                                />
                                {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
                            </div>

                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Password</label>
                                <input
                                    type="password"
                                    value={newMember.password}
                                    onChange={(e) =>
                                        setNewMember({ ...newMember, password: e.target.value })
                                    }
                                    className="block w-full mt-1 p-2 border rounded"
                                    required
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Mobile No.</label>
                                <input
                                    type="number"
                                    value={newMember.mobile}
                                    onChange={(e) =>
                                        setNewMember({ ...newMember, mobile: e.target.value })
                                    }
                                    className="block w-full mt-1 p-2 border rounded"
                                    required
                                />
                            </div>

                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Plan</label>
                                <select
                                    value={newMember.plan}
                                    onChange={(e) => setNewMember({ ...newMember, plan: e.target.value })}
                                    className="notranslate block w-full mt-1 p-2 border rounded"
                                    required
                                >
                                    <option value="">Select a Plan</option>
                                    {plans.map((plan) => (
                                        <option key={plan.name} value={plan.name}>{plan.name} {plan.price}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Upload Plan Proof </label>
                                <FileInput onChange={handlePlanProof} className="block w-full mt-1 p-2 border rounded" />
                            </div>

                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => setIsFormVisible(false)}
                                    className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                                >
                                    Cancel
                                </button>
                                <button

                                    type="submit"
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                >
                                    Add Member
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <div className="mt-8">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">Your Network</h2>

                {(plan && plan !== "Bronze") || user.isHead ?
                    <button
                        onClick={() => setIsFormVisible(true)}
                        className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
                    >
                        Add Member
                    </button> : <h1 className="text-2xl font-bold text-gray-800 mb-4">Enroll plan <p className="notranslate">Silver</p> or Gold To Add Members</h1>}

                <div className="bg-white shadow-md rounded-lg p-6 overflow-x-auto">
                    <table className="table-auto w-full">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="px-4 py-2 text-left">Sr No</th>
                                <th className="px-4 py-2 text-left">Date</th>
                                <th className="px-4 py-2 text-left">Commission Type</th>
                                <th className="px-4 py-2 text-left">From Member Code</th>
                                <th className="px-4 py-2 text-left">From Member Name</th>
                                <th className="px-4 py-2 text-left">Earnings</th>
                                <th className="px-4 py-2 text-left">Commmission</th>
                                <th className="px-4 py-2 text-left">Total Income</th>
                                <th className="px-4 py-2 text-left">Cotton Deposit</th>
                            </tr>
                        </thead>

                        <tbody>
                            {network.map((member, index) => (
                                <tr key={index} className="border-b">
                                    <td className="px-4 py-2">{index + 1}</td>
                                    <td className="px-4 py-2">{new Date(member?.registeredDate).toLocaleDateString() || ""}</td>
                                    <td className="px-4 py-2">{member.plan || 'N/A'}</td>
                                    <td className="notranslate px-4 py-2">{member?.referralCode || 'N/A'}</td>
                                    <td className="px-4 py-2">{member?.name || 'N/A'}</td>
                                    <td className="px-4 py-2">{member?.earnings || 0}</td>
                                    <td className="px-4 py-2">{member?.commission || 0}</td>
                                    <td className="px-4 py-2">{member?.earnings + member.commission || 0}</td>
                                    <td className="px-4 py-2">
                                        <button
                                            onClick={() => handleViewMember(member)}
                                            className="bg-green-500 text-white px-3 py-1 rounded"
                                        >
                                            View Details
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default AddUser;