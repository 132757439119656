import axios from 'axios';

const api = axios.create({
  baseURL: '/api', // Replace with your backend base URL
});

api.interceptors.request.use(
  
  (config)=>{
    console.log(config.data);
    if(!config.data || (!(config.data instanceof FormData) && Object.keys(config.data).length==0)){
      return config;
    }
    let loading = document.getElementById("loading-box");
    loading.style.display = "block";
    return config;
  },
  (error)=>{
    return error;
  }
)
api.interceptors.response.use( // Add a response interceptor beacuse we want to handle errors globally and error fetched from the backend will be in the response object to  error.response.data object
    (response) => {
      let loading = document.getElementById("loading-box");
      loading.style.display = "none";  
      return response;
    },
    (error) => {
      let loading = document.getElementById("loading-box");
      loading.style.display = "none";
      console.log(error);
      return Promise.reject(error.response.data);
    }
  );
  
export default api;
