import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../utils/api";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useAuth } from "../hooks/AuthProvider";

const SideNavbar = () => {
  const [isOpen, setIsOpen] = useState(false); // Sidebar open state
   const menuRef = useRef(null);
  const navigate = useNavigate();
  const navigateTo = (url)=>{
      setIsOpen(false);
      navigate(url);
  }
  const user = useAuth();
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    mobile: "",
    plan: "",
    earnings: 0,
    referralCode: "",
    image: "",
  });

  const handleLogout = () => {
    user.logOut();
  };

  const fetchProfile = async () => {
    const token = user.token;
    if (token) {
      try {
        const { data } = await axios.get("/user/profile", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProfile(data);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

   useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          setIsOpen(false);
        }
      };
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [menuRef]);

  return (
    <>
      {/* Sidebar */}
      <div ref={menuRef}
        className={`fixed z-20 top-0 left-0 h-screen w-64 bg-gray-800 text-white p-4 transform transition-transform duration-300 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {/* User Info */}
        <div className="flex flex-col items-center mt-6">
          <img
            src={profile.image || "/default-avatar.png"}
            alt="User Avatar"
            className="w-16 h-16 rounded-full border-2 border-gray-600"
          />
          <h2 className="mt-2 text-lg font-bold">{profile.name}</h2>
          <p className="text-sm text-gray-400 notranslate">Referral Code: {profile.referralCode}</p>
        </div>

        {/* Menu */}
        <div className="mt-6">
          <ul>
            <li
              onClick={() => navigateTo("/profile")}
              className="px-4 py-2 hover:bg-gray-600 cursor-pointer flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5.121 17.804A4 4 0 115 7a4 4 0 010 10.804zM14.121 17.804A4 4 0 1114 7a4 4 0 010 10.804z"
                />
              </svg>
              Profile
            </li>
            <li
              onClick={() => navigateTo("/change-password")}
              className="px-4 py-2 hover:bg-gray-600 cursor-pointer flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 11c0-2.121 0-3.182-.879-4.061C10.242 6 9.121 6 7 6m10 10c1.121 0 2.242 0 3.121-.879C21 13.182 21 12.121 21 10m0 4V6m-6 4v2m0 4v2m4-10h-8"
                />
              </svg>
              Change Password
            </li>
            <li
              onClick={() => navigateTo("/network")}
              className="px-4 py-2 hover:bg-gray-600 cursor-pointer flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 7V3m0 0a4 4 0 014-4 4 4 0 014 4v4m-4 4a4 4 0 11-4-4 4 4 0 014 4zm-4 4h8"
                />
              </svg>
              Member Level
            </li>
           {user.isHead && <li
              onClick={() => navigateTo("/adduser")}
              className="px-4 py-2 hover:bg-gray-600 cursor-pointer flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 7V3m0 0a4 4 0 014-4 4 4 0 014 4v4m-4 4a4 4 0 11-4-4 4 4 0 014 4zm-4 4h8"
                />
              </svg>
              Network
            </li>}
            <li
              onClick={handleLogout}
              className="px-4 py-2 hover:bg-gray-600 cursor-pointer flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-5 h-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 16l4-4m0 0l-4-4m4 4H7"
                />
              </svg>
              Sign Out
            </li>
          </ul>
        </div>
      </div>

      {/* Toggle Button */}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="fixed top-9 mr-5 left-4 bg-gray-800 text-white p-2 rounded-lg z-40"
      >
        {isOpen ? (
          <XMarkIcon className="h-6 w-6" />
        ) : (
          <Bars3Icon className="h-6 w-6" />
        )}
      </button>
    </>
  );
};

export default SideNavbar;
