import React from 'react';
import { Link } from 'react-router-dom';
import {  FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="h-17 bg-gradient-to-r from-purple-500 to-pink-500 text-purple-800 py-6">
      <div className="container mx-auto flex  flex-col md:flex-row justify-between items-center">
        <div className="text-center md:text-left mb-4 md:mb-0">
          <h1 className="text-lg font-bold">Shri Sai Enterprises</h1>
          <p className="text-sm">
            Empowering your business with innovative opportunities.
          </p>
        </div>

        <div className="flex flex-row mb-4 md:mb-0">
          <div className='flex flex-col'>
          <Link to="/about" className="text-white hover:underline">
            About Us
          </Link>
          <Link to="/faq" className="text-white hover:underline">
            FAQs
          </Link>
          <Link to="/contact" className="text-white hover:underline">
            Contact Us
          </Link>
          <Link to="/privacypolicy" className="text-white hover:underline">
            Privacy Policy
          </Link>
          <Link to="/terms-and-conditions" className="text-white hover:underline">
            Terms & Conditions
          </Link>
          </div>
          <div className="flex justify-center gap-4 mt-3">
          <FaFacebook className="text-xl cursor-pointer" />
          <FaInstagram className="text-xl cursor-pointer" />
          <FaTwitter className="text-xl cursor-pointer" />
        </div>
        </div>

        <div className="text-center md:text-right">
          <p className="text-sm">
            &copy; {new Date().getFullYear()} Shri Sai Enterprises. All Rights Reserved.
          </p>
          <p className="text-sm">
            Designed with ❤️ by Shri Sai Enterprises Team
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
