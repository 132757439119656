import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from '../utils/api';
import { openDB } from 'idb';
import { jwtDecode } from 'jwt-decode'

const AuthContext = createContext();

const AuthProvider = ({ children }) => {

    const [token, setToken] = useState("");
    const [isHead, setHead] = useState(false);
    const [fetchingToken, setFetchingToken] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAuthData = async () => {
            const db = await openDB('auth-db', 1, {
                upgrade(db) {
                    db.createObjectStore('auth', { keyPath: 'key' });
                },
            });
            const token = await db.get('auth', 'token');
            const isHead = await db.get('auth', 'isHead');
            const decoded = token?.value ? jwtDecode(token.value) : "";
            if (decoded && decoded.exp && decoded.exp * 1000 < (new Date()).getTime()) {
                return logOut();
            }
            setToken(token?.value || "");
            setHead(isHead?.value);
            setFetchingToken(false);
        };
        fetchAuthData();
    }, []);


    const loginAction = async (mobile, password) => {
        try {
            const { data } = await axios.post('/user/login', { mobile, password });

            if (data) {
                setToken(data.token);
                setHead(data.isHead);

                const db = await openDB('auth-db', 1);
                await db.put('auth', { key: 'token', value: data.token });
                await db.put('auth', { key: 'isHead', value: data.isHead });
                navigate("/dashboard");
                return;
            }
        } catch (error) {
            console.error("Login failed", error);
            alert(error.error);
        }
    };

    const logOut = async () => {
        setToken("");
        setHead(false);
        setFetchingToken(true);

        const db = await openDB('auth-db', 1);
        await db.delete('auth', 'token');
        await db.delete('auth', 'isHead');
        setFetchingToken(false);
        navigate("/login");
    };

    return (fetchingToken ? <div>Loading...</div> : (
        <AuthContext.Provider value={{ token, loginAction, logOut, isHead }}>
            {children}
        </AuthContext.Provider>)
    );

};

export default AuthProvider;

export const useAuth = () => {
    return useContext(AuthContext);
};
