import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
Notification.requestPermission().then((result) => {
  console.log(result);
  if ('serviceWorker' in navigator) {

    navigator.serviceWorker
      .register('/sw.js') // Service worker file is in the public folder
      .then(registration => {
        console.log('Service Worker registered:', registration);
           // Send token to service worker when available
           const token = localStorage.getItem('token');
           if (token) {
               registration.active.postMessage({ type: 'token', token });
           }
      })
      .catch(error => {
        console.error('Service Worker registration failed:', error);
      });
  }
}).catch(alert);

