// components/ImagePreviewModal.js
import React from 'react';

const ImagePreviewModal = ({ imageUrl, onClose }) => {
  if (!imageUrl) {
    return null; // Don't render anything if there's no image URL
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
      <div className="bg-white p-6 rounded shadow-lg max-w-lg">
        <h2 className="text-2xl font-bold mb-4">Image Preview</h2>
        <img src={imageUrl} alt="Payment Proof" className="max-w-full h-auto" />
        <div className="flex justify-end mt-4">
        <a
            href={imageUrl}
            download
            className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
          >
            Download
          </a>
          <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImagePreviewModal;