import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../utils/api'; // Import your axios instance
import PlanCard from '../components/PlanCard';
import { useAuth } from '../hooks/AuthProvider';
// import QRCode from 'react-qr-code';
import { useQRCode } from 'next-qrcode';
import FileInput from '../components/basic/FileInput';

const Plans = () => {
  const { Image } = useQRCode();
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [upiUrl, setUpiUrl] = useState('');
  const [txnid, setTxnId] = useState(null);
  const [upiMessage, setUpiMessage] = useState(null);
  const [planProof, setPlanProof] = useState(null);
  const [upiAmount, setUpiAmount] = useState(null);
  const navigate = useNavigate();
  const user = useAuth();

  const handlePlanProof = (e) => {
    setPlanProof(e.target.files[0]);
  };
  const plans = [
    {
      plan: 'Bronze',
      price: 750,
      features: ['200g cotton for practice', 'Round cotton wick production'],
    },
    {
      plan: 'Silver',
      price: 1500,
      features: [
        '300g cotton for practice',
        'Round & long wicks production',
        'Packaging included',
      ],
    },
    {
      plan: 'Gold',
      price: 2500,
      features: [
        '400g cotton for practice',
        'Round & long wicks production',
        'Vastramala production',
        'Packaging included',
      ],
    },
  ];

  const handleChoosePlan = (plan) => {
    if (!user.token) {
      navigate('/login'); // Redirect to login if not logged in
      return;
    }
    setSelectedPlan(plan);
    setIsPopupVisible(true); // Show confirmation popup
  };

  const confirmEnrollment = async () => {
    try {
      // Call the backend API to enroll in the selected plan
      const { data } = await axios.post(
        '/user/enroll',
        { plan: selectedPlan },
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
      console.log(data);
      const form = document.createElement('form');
      form.action = data.url;
      form.method = 'POST';

      for (const key in data.payLoad) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = data.payLoad[key];
        form.appendChild(input);
      }

      const hashInput = document.createElement('input');
      hashInput.type = 'hidden';
      hashInput.name = 'hash';
      hashInput.value = data.payLoad.hash;
      form.appendChild(hashInput);

      document.body.appendChild(form);
      form.submit();
      setIsPopupVisible(false); // Close the popup
    } catch (error) {
      console.error('Failed to enroll in the plan:', error);
      alert('Admin did not approve you. ' + error.error);
    }
  };

  const enrollUpi = async () => {
    try {
      // Call the backend API to enroll in the selected plan
      const { data } = await axios.post(
        '/user/enroll-upi',
        { plan: selectedPlan },
        { headers: { Authorization: `Bearer ${user.token}` } }
      );
      console.log(data);
      setUpiUrl(data.upiUrl);
      setTxnId(data.txnid);
      setUpiMessage(data.message);
      setUpiAmount(data.amount);
      setIsModalOpen(true); // Open the UPI modal
    } catch (error) {
      console.error('Failed to enroll in the plan:', error);
      alert('Enroll UPI Failed. ' + error.error);
    }
  };

  const requestPlan = async () => {
    try {
      const formData = new FormData();
      formData.append('planProof', planProof);
      formData.append('plan', selectedPlan);
      const { data } = await axios.post(
        '/user/requestplanProof',
        formData,
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log(data);
      alert(data.message);
      setIsModalOpen(false);
      setIsPopupVisible(false);
    } catch (error) {
      console.error('Failed to upload plan proof:', error);
      alert('Failed to upload plan proof. ' + error.error);
    }
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 p-10">
      {plans.map((plan, index) => (
        <PlanCard
          key={index}
          plan={plan.plan}
          price={plan.price}
          features={plan.features}
          onChoose={() => handleChoosePlan(plan.plan)} // Pass the plan name to handleChoosePlan
        />
      ))}

      {isPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
          <div className="bg-white p-6 rounded shadow-lg w-96">
            <h2 className="text-2xl font-bold mb-4">Confirm Enrollment</h2>
            <p>Are you sure you want to enroll in the <strong>{selectedPlan}</strong> plan?</p>
            <div className="flex justify-end mt-6">


              <button
                onClick={() => setIsPopupVisible(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
              >
                Cancel
              </button>
              {/* <button
                onClick={confirmEnrollment}
                className="bg-blue-500 text-white px-4 py-2 rounded"
              >
                Confirm
              </button> */}
              <button
                onClick={enrollUpi}
                className="bg-green-500 text-white px-4 py-2 rounded"
              >
                UPI Payment
              </button>
            </div>
          </div>
        </div>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
          <div className="bg-white p-6 rounded shadow-lg w-96">
            <h2 className="text-2xl font-bold mb-4">UPI Payment</h2>
            {/* <p className="mb-4">{upiMessage}</p> */}
            <p className="mb-4">Download the Image and scan from phonePe(or Google pay, PayTM etc.) and send the money or pay to </p>
            {/* <p className="mb-4">Transaction ID: {txnid}</p> */}
            <p className="mb-4">Amount: {upiAmount}</p>
            {/* <a href={upiUrl} className="bg-blue-500 text-white px-4 py-2 rounded" target="_blank" rel="noopener noreferrer">

              Pay Now
            </a> */}

            <br />
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Upload Plan Proof </label>
              <FileInput onChange={handlePlanProof} className="block w-full mt-1 p-2 border rounded" />
            </div>
            {/* <p className='mt-8'>Scan the QR code to pay using UPI</p> */}
            {/* <QRCode value={upiUrl} size={200} /> */}
            <Image
              text={upiUrl}
              options={{
                type: 'image/jpeg',
                quality: 0.8,
                margin: 3,
                scale: 4,
                color: {
                  dark: '#000000',
                  light: '#FFFFFF',
                },
              }}
            />
            <div className="flex justify-end mt-6">
              <button
                onClick={requestPlan}
                className="bg-green-500 text-white px-4 py-2 rounded"
              >
                Submit Proof
              </button>
            </div>
            <div className="flex justify-end mt-6">
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Plans;
