const PlanCard = ({ plan, price, features, onChoose }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="notranslate text-2xl font-bold text-gray-800 mb-4">{plan}</h2>
      <p className="text-gray-600 mb-4">Price: ₹{price}</p>
      <ul className="text-gray-600 mb-6">
        {features.map((feature, index) => (
          <li key={index}>• {feature}</li>
        ))}
      </ul>
      <button
        onClick={onChoose}
        className="bg-blue-500 text-white px-4 py-2 rounded"
      >
        Choose Plan
      </button>
    </div>
  );
};

export default PlanCard;
