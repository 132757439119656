
import React from 'react';

const TermsAndConditions = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Terms & Conditions</h1>
            <p>Effective Date: 2025-02-28</p>

            <h2 className="text-xl font-semibold mb-2">1. General Terms</h2>
            <p>
                The website is for purchasing work-from-home plans and cotton wick products. Any individual joining the work-from-home program agrees to follow company guidelines. Shri Sai Enterprises reserves the right to modify policies at any time.
            </p>

            <h2 className="text-xl font-semibold mb-2">2. Payment & Pricing</h2>
            <p>
                Prices listed on the website are final and subject to change without notice. Payments must be made via available payment methods.
            </p>

            <h2 className="text-xl font-semibold mb-2">3. Work-from-Home Guidelines</h2>
            <p>
                Participants must use the raw materials provided by Shri Sai Enterprises. Final products must meet quality standards; otherwise, the company has the right to reject them. Payment for completed work will be processed only after quality checks.
            </p>

            <h2 className="text-xl font-semibold mb-2">4. Intellectual Property</h2>
            <p>
                All content on the website, including logos and product descriptions, is the property of Shri Sai Enterprises. Unauthorized use or reproduction of content is strictly prohibited.
            </p>

            <h2 className="text-xl font-semibold mb-2">5. Limitation of Liability</h2>
            <p>
                Shri Sai Enterprises is not liable for any indirect or accidental loss related to website usage or product purchases. We reserve the right to refuse service to anyone violating company policies.
            </p>
        </div>
    );
};

export default TermsAndConditions;