import React from 'react';



const FAQ = () => {
  const faqs = [
    {
      question: "What is Shri Sai Enterprises?",
      answer: "Shri Sai Enterprises is an MLM company that offers a platform to generate income through membership plans and product creation opportunities.",
    },
    {
      question: "How do I start earning?",
      answer: "You can start earning by choosing one of our membership plans—Bronze, Silver, or Gold. Each plan provides raw materials and opportunities to produce and sell finished products.",
    },
    {
      question: "What products can I create?",
      answer: "Depending on your plan, you can create round cotton wicks, long wicks, and vastramala, with packaging included for higher plans.",
    },
    {
      question: "What is the commission structure?",
      answer: "Our commission structure allows you to earn income from up to 10 levels in your network. Each plan has a detailed breakdown of earnings per level.",
    },
    {
      question: "Is there a buyback guarantee?",
      answer: "Yes! We guarantee buyback for all finished products that meet our quality standards.",
    },
    {
      question: "How can I upgrade my membership plan?",
      answer: "You can upgrade your plan at any time through the dashboard. Simply select your desired plan and complete the payment process.",
    },
  ];

  return (
    <div>
     
      <div className="bg-gray-100 min-h-screen py-8 px-4">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">FAQs</h1>
        <div className="max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <div key={index} className="mb-6">
              <h3 className="text-xl font-semibold text-gray-800 mb-2">{faq.question}</h3>
              <p className="text-gray-600">{faq.answer}</p>
            </div>
          ))}
        </div>
      </div>
      
    </div>
  );
};

export default FAQ;
