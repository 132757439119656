import { useState } from 'react';
import { useAuth } from '../hooks/AuthProvider';
import axios from '../utils/api'
import { useNotifications } from '../context/NotificationContext';
export default function CommissionRequestModal({ onClose }) {
    const user = useAuth();
    const [commissionAmount, setCommissionAmount] = useState(0);
    const {fetchNotifications} = useNotifications();

    const handleRequestCommission = async (e) => {
        try {
            e.preventDefault();
            const data = await axios.post("/member/commission-request", { commissionAmount },
                { headers: { Authorization: `Bearer ${user.token}` } }
            );
            fetchNotifications();
            onClose();
            alert("Commission Requested Successfully");
        } catch (error) {
            console.log(error.error);
            alert(error.error)
        }
    }
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
            <div className="bg-white p-6 rounded shadow-lg w-96">
                <h2 className="text-2xl font-bold mb-4">Request Commission</h2>
                <form onSubmit={handleRequestCommission}>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Amount</label>
                        <input
                            type="number"
                            value={commissionAmount}
                            onChange={(e) => setCommissionAmount(e.target.value)}
                            className="block w-full mt-1 p-2 border rounded"
                            required
                            min="1"
                        />
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-4 py-2 rounded"
                        >
                            Request
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}
