import { FaCheckCircle, FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

export default function About() {
  return (
    <div>
      {/* Company Overview Section */}
      <section className="py-16 px-4 text-center">
        <h1 className="text-4xl font-bold">About Shri Sai Enterprises</h1>
        <p className="mt-4 text-lg text-gray-600">Shri Sai Enterprises is dedicated to crafting high-quality cotton wicks, upholding India’s cultural heritage with a commitment to excellence.</p>
        <div className="flex justify-center my-6">
        <img src="/cotton.jpg" alt="Cotton Wicks" className="w-3/4 max-w-4xl rounded-lg shadow-lg" />
      </div>
      </section>

      {/* Vision and Mission Section */}
      <section className="py-16 bg-gray-100">
        <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-8 items-center">
          <img src="/cotton2.jpeg" alt="Vision" className="w-full h-auto rounded-lg shadow-md" />
          <div>
            <h2 className="text-3xl font-bold">Our Vision</h2>
            <p className="mt-4 text-gray-600">To be the most trusted provider of premium cotton wicks, ensuring quality and tradition in every product.</p>
          </div>
        </div>
        <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-8 items-center mt-12">
          <div>
            <h2 className="text-3xl font-bold">Our Mission</h2>
            <p className="mt-4 text-gray-600">We strive to produce top-quality wicks, maintain sustainable practices, and support our partners in their spiritual and commercial endeavors.</p>
          </div>
          <img src="/cotton2.jpeg" alt="Mission" className="w-full h-auto rounded-lg shadow-md" />
        </div>
      </section>

      {/* Our Journey Section */}
      <section className="py-16 text-center">
        <h2 className="text-3xl font-bold">Our Journey</h2>
        <div className="mt-6 space-y-6 max-w-3xl mx-auto">
          <div className="flex items-center space-x-4">
            <FaCheckCircle className="text-green-500 text-xl" />
            <p className="text-lg">Founded in 2010 with a vision to create the best cotton wicks.</p>
          </div>
          <div className="flex items-center space-x-4">
            <FaCheckCircle className="text-green-500 text-xl" />
            <p className="text-lg">Expanded to serve nationwide distributors by 2015.</p>
          </div>
          <div className="flex items-center space-x-4">
            <FaCheckCircle className="text-green-500 text-xl" />
            <p className="text-lg">Continuing to innovate and grow in 2024 and beyond.</p>
          </div>
        </div>
      </section>

      {/* Future Endeavors Section */}
      <section className="py-16 bg-gray-100">
        <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-8 items-center">
          <img src="/cotton2.jpeg" alt="Future" className="w-full h-auto rounded-lg shadow-md" />
          <div>
            <h2 className="text-3xl font-bold">Future Endeavors</h2>
            <p className="mt-4 text-lg text-gray-600">We are expanding our product line to include premium organic wicks and introducing automation for sustainable production.</p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-16 text-center ">
        <h2 className="text-3xl font-bold mb-6">What Our Customers Say</h2>
        <div className="flex overflow-x-auto space-x-6 bg-gray-300 px-4 max-w-6xl h-50 mx-auto">
          <div className="bg-white p-6 rounded-lg shadow-md min-w-[300px]">
            <p className="text-gray-600 italic">“Shri Sai Enterprises provides the best quality wicks. Their commitment to tradition and quality is commendable.”</p>
            <h3 className="mt-4 font-semibold">- Ramesh Gupta</h3>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md min-w-[300px]">
            <p className="text-gray-600 italic">“Reliable and high-quality products! Our pujas have never been better.”</p>
            <h3 className="mt-4 font-semibold">- Anjali Sharma</h3>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-md min-w-[300px]">
            <p className="text-gray-600 italic">“I love their wicks. They burn perfectly and last long.”</p>
            <h3 className="mt-4 font-semibold">- Rajesh Verma</h3>
          </div>
        </div>
      </section>

      {/* Call-to-Action Section */}
      <section className="py-16 bg-gray-500 text-center text-white">
        <h2 className="text-3xl font-bold">Join Our Network</h2>
        <p className="mt-2">Become a part of our journey by partnering with us.</p>
        <button className="mt-4 px-6 py-2 text-lg bg-yellow-500 text-black hover:bg-gray-200 rounded-lg">Get in Touch</button>
      </section>
    </div>
  );
}
