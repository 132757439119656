import { useDashboard } from "../App";

export default function CommissionHistoryModal({onClose, handleOpenImageModal}) {
    const { commissionHistory } = useDashboard();
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-10 ">
            <div className="bg-white p-6 rounded shadow-lg w-full max-w-3xl">
                <h2 className="text-2xl font-bold mb-4">Your Commission History</h2>
                <div className="h-[70vh] overflow-auto">
                    <table className="table-auto w-full">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="px-4 py-2 text-left">Date</th>
                                <th className="px-4 py-2 text-left">Amount</th>
                                <th className="px-4 py-2 text-left">Status</th>
                                <th className="px-4 py-2 text-left">Payment Proof</th>
                            </tr>
                        </thead>
                        <tbody>
                            {commissionHistory.map((commissionHistoryItem, i) => (
                                <tr key={i} className="border-b">
                                    <td className="px-4 py-2">{new Date(commissionHistoryItem.date).toLocaleDateString()}</td>
                                    <td className="px-4 py-2">Rs. {commissionHistoryItem.amount}</td>
                                    <td className="px-4 py-2">{commissionHistoryItem.status}</td>
                                    <td className="px-4 py-2">
                                        {/* Add image */}
                                        {commissionHistoryItem.paymentProof && (
                                            <button
                                                onClick={() => handleOpenImageModal(commissionHistoryItem.paymentProof)}
                                                className="bg-blue-500 text-white px-4 py-2 rounded mr-2"
                                            >
                                                View Image
                                            </button>
                                        )}
                                        {/* {commissionHistoryItem.status === 'Delivered' && commissionHistoryItem.depositReturnRequested === false && (
                                            <button
                                                onClick={() => handleRequestDepositReturn(commissionHistoryItem._id)}
                                                className="bg-blue-500 text-white px-4 py-2 rounded"
                                            >
                                                Request Cotton Deposit
                                            </button>
                                        )}
                                        {commissionHistoryItem.status === 'Delivered' && commissionHistoryItem.depositReturnStatus !== null && (
                                            <span className="text-gray-600">Return Status: {commissionHistoryItem.depositReturnStatus}</span>
                                        )} */}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-end mt-6">
                    <button
                        onClick={onClose}
                        className="bg-gray-500 text-white px-4 py-2 rounded"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    )
}