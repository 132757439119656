// components/DepositReturnRequest.js
import React, { useState } from 'react';
import axios from 'axios';
import FileInput from './basic/FileInput';
const DepositReturnRequest = ({ order, onClose, onUpdateStatus }) => {
  const [adminImage, setAdminImage] = useState(null);

  const handleImageChange = (e) => {
    setAdminImage(e.target.files[0]);
  };
  const handleApprove = async () => {

    const formData = new FormData();
    formData.append('status', 'Approved');
    formData.append('cottonReturnQuantity', order.quantity)
    if (adminImage) {

      formData.append('adminImage', adminImage); // Append the image file
      console.log(formData)
    }

    //  try {
    //   const response = await axios.put(`/member/cotton/orders/${order._id}/return`, formData, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem('token')}`, // Get token from wherever you store it
    //       'Content-Type': 'multipart/form-data', // Important for file uploads
    //     },
    //   });

    onUpdateStatus(order._id, 'Approved', null, order.quantity, formData);
    onClose();
    // } catch (error) {
    //   console.error('Error approving deposit return:', error);
    //   // Handle error (e.g., display error message)
    // }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
      <div className="bg-white p-6 rounded shadow-lg w-96">
        <h2 className="text-2xl font-bold mb-4">Process Deposit Return</h2>
        <p><strong className='mr-2'>Member:</strong> {order.member.name}</p>
        <p><strong className='mr-2'>Order ID:</strong> {order._id}</p>
        <p><strong className='mr-2'>Total Quantity:</strong> {order.quantity} kg</p>
        <p><strong className='mr-2'>Return Amount:</strong> {order.quantity * 700} </p>
        <p><strong className='mr-2'>Address:</strong> {order.address} </p>
        <p><strong className='mr-2'>Mobile:</strong> {order.member.mobile} </p>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Admin Upload Image:</label>
          <FileInput accept="image/*" onChange={handleImageChange} className="block w-full mt-1" />
        </div>
        <div className="flex justify-end">
          <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded mr-2">
            Cancel
          </button>
          <button onClick={handleApprove} className="bg-green-500 text-white px-4 py-2 rounded">
            Approve Return
          </button>
        </div>
      </div>
    </div>
  );
};

export default DepositReturnRequest;