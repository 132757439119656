import React, { useEffect, useState } from "react";
import axios from '../utils/api';
import { useAuth } from "../hooks/AuthProvider";
import ImagePreviewModal from '../components/ImagePreviewModal';
import { useNotifications } from '../context/NotificationContext';

const levelsStr = {
    O: 0,
    F: 1,
    S: 2,
    TH: 3,
    FR: 4,
    FV: 5,
    SI: 6,
    SV: 7,
    E: 8,
    N: 9,
    TN: 10,
};

const NetworkPage = () => {
    const user = useAuth();
    const [network, setNetwork] = useState([]);
    const [viewProof, setViewProof] = useState(false);
    const [selectedImageProof, setSelectedImageProof] = useState(null);
    const [selectedMember, setSelectedMember] = useState(null);
    const [isMemberModalVisible, setIsMemberModalVisible] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [confirmAction, setConfirmAction] = useState(null);
    const [action, setAction] = useState(null);
    const [isUpdatePlanModalVisible, setIsUpdatePlanModalVisible] = useState(false);
    const [newPlan, setNewPlan] = useState('');
    const { fetchNotifications } = useNotifications();
    const [planProof, setPlanProof] = useState(null); 

    const fetchNetwork = async () => {
        try {
            const { data } = await axios.get("/member/network", {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            if (data.length > 0) {
                setNetwork(data[0].fullNetwork);
            }
        } catch (err) {
            console.error('Failed to fetch network data:', err);
        }
    };

    useEffect(() => {
        fetchNetwork();
    }, []);

    const handleViewMember = (member) => {
        setSelectedMember(member);
        setIsMemberModalVisible(true);
    };

    const getLevel = (referralCode) => {
        let lvl = referralCode.split('L')[0];
        return levelsStr[lvl] || 0;
    };

    const showImageModal = (idProof) => {
        setViewProof(true);
        setSelectedImageProof(idProof);
    };

    const handleApproveRegistration = async (userId) => {
        try {
            await axios.post('/member/approve-registration', { userId }, {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            setNetwork((prevNetwork) =>
                prevNetwork.map((member) =>
                    member._id === userId ? { ...member, isApproved: true } : member
                )
            );
            fetchNotifications();
        } catch (err) {
            console.error('Failed to approve registration:', err);
        }
    };

    const handleDeleteMember = async (userId) => {
        try {
            await axios.delete(`/member/delete-member/${userId}`, {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            setNetwork((prevNetwork) =>
                prevNetwork.filter((member) => member._id !== userId)
            );
            fetchNotifications();
        } catch (err) {
            console.error('Failed to delete member:', err);
        }
    };

    const handleApprovePlan = async (userId) => {
        try {
            await axios.post('/member/approve-plan', { userId }, {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            setNetwork((prevNetwork) =>
                prevNetwork.map((member) =>
                    member._id === userId ? { ...member, planApproved: true } : member
                )
            );
            fetchNotifications();
        } catch (err) {
            console.error('Failed to approve plan:', err);
        }
    };

    const handleUpdatePlan = async () => {
        try {
            await axios.post('/admin/approve/plan', { userId: selectedMember._id, plan: newPlan }, {
                headers: { Authorization: `Bearer ${user.token}` },
            });
            setNetwork((prevNetwork) =>
                prevNetwork.map((member) =>
                    member._id === selectedMember._id ? { ...member, plan: newPlan, planApproved: true } : member
                )
            );
            setIsUpdatePlanModalVisible(false);
            fetchNotifications();
        } catch (err) {
            console.error('Failed to update plan:', err);
        }
    };

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedNetwork = [...network].sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];

        if (sortConfig.key === 'level') {
            aValue = getLevel(a.referralCode);
            bValue = getLevel(b.referralCode);
        }

        if (aValue < bValue) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const getSortIndicator = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc' ? '▲' : '▼';
        }
        return '';
    };

    const openConfirmModal = (action) => {
        setConfirmAction(() => action);
        setConfirmModalVisible(true);
    };

    const handleConfirmAction = () => {
        if (confirmAction) {
            confirmAction();
        }
        setConfirmModalVisible(false);
    };

    const openUpdatePlanModal = (member) => {
        setSelectedMember(member);
        setIsUpdatePlanModalVisible(true);
        setNewPlan(member.plan || 'Bronze'); // Default to Bronze if no plan or empty
    };

    const getAvailablePlans = (currentPlan) => {
        const plans = ['Bronze', 'Silver', 'Gold'];
        if(!currentPlan){
            return plans;
        }
        if ( currentPlan === 'Bronze') {
            return plans.slice(1);
        } else if (currentPlan === 'Silver') {
            return plans.slice(2);
        }
        return [];
    };

    return (
        <>
            {viewProof && <ImagePreviewModal imageUrl={selectedImageProof} onClose={() => setSelectedImageProof(null) && setViewProof(false)} />}
            <div className="flex">
                <div className="p-4 w-3/4">
                    <h1 className="text-2xl font-bold mb-4">Member Details</h1>
                    <div className="bg-white shadow-md rounded-lg p-4 w-[95vw] overflow-y-auto">
                        <table className="border-collapse border border-gray-300">
                            <thead>
                                <tr className="bg-gray-200">
                                    <th className="border border-gray-300 px-4 py-2 cursor-pointer" onClick={() => handleSort('index')}>Sr No {getSortIndicator('index')}</th>
                                    <th className="border border-gray-300 px-4 py-2">Photo</th>
                                    <th className="border border-gray-300 px-4 py-2 cursor-pointer" onClick={() => handleSort('referralCode')}>Referral Code {getSortIndicator('referralCode')}</th>
                                    <th className="border border-gray-300 px-4 py-2 cursor-pointer" onClick={() => handleSort('level')}>Level {getSortIndicator('level')}</th>
                                    <th className="border border-gray-300 px-4 py-2 cursor-pointer" onClick={() => handleSort('registeredDate')}>Date {getSortIndicator('registeredDate')}</th>
                                    <th className="border border-gray-300 px-4 py-2 cursor-pointer" onClick={() => handleSort('plan')}>Commission Type {getSortIndicator('plan')}</th>
                                    <th className="border border-gray-300 px-4 py-2 cursor-pointer" onClick={() => handleSort('name')}>Name {getSortIndicator('name')}</th>
                                    <th className="border border-gray-300 px-4 py-2">ID Proof</th>
                                    <th className="border border-gray-300 px-4 py-2">Pan ID Proof</th>
                                    <th className="border border-gray-300 px-4 py-2">Member Other Details</th>
                                    <th className="border border-gray-300 px-4 py-2">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedNetwork.map((member, index) => (
                                    <tr key={member._id} className="border border-gray-300">
                                        <td className="border border-gray-300 px-4 py-2"><div className="min-h-[100%]">{index + 1}</div></td>
                                        <td><img src={member.image} alt="Avatar" className="w-20 h-20 rounded-full mx-auto mb-4" /></td>
                                        <td className="border border-gray-300 px-4 py-2">{member.referralCode}</td>
                                        <td className="notranslate border border-gray-300 px-4 py-2">{getLevel(member.referralCode)}</td>
                                        <td className="border border-gray-300 px-4 py-2">{new Date(member.registeredDate).toLocaleDateString()}</td>
                                        <td className="notranslate border border-gray-300 px-4 py-2">
                                            {member.plan}
                                            {planProof && (
                                    <img src={planProof} alt="Payment Proof" className="max-w-full h-auto mb-4" />
                                )}
                                            {member.requestedPlan  && member.plan !== 'Gold' && (
                                                <button
                                                    onClick={() => setPlanProof(member.requestedPlanProof)}
                                                    className="bg-green-500 text-white px-2 py-1 rounded ml-2"
                                                >
                                                    Approve Plan
                                                </button>
                                            )}
                                            <ImagePreviewModal imageUrl={planProof} onClose={() => setPlanProof(null)} />
                                            {member.plan !== 'Gold'&& (<button
                                                onClick={() => openUpdatePlanModal(member)}
                                                className="bg-blue-500 text-white px-2 py-1 rounded ml-2"
                                            >
                                                Update Plan
                                            </button>)
                                            }
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2">{member.name}</td>
                                        <td className="border border-gray-300 px-4 py-2">{member.idProof ?
                                            <button
                                                onClick={() => showImageModal(member.idProof)}
                                                className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
                                                View IDProof
                                            </button> : "ID Proof not submitted"}
                                        </td>
                                        <td className="border border-gray-300 px-4 py-2">{member.panidProof ?
                                            <button
                                                onClick={() => showImageModal(member.panidProof)}
                                                className="bg-blue-500 text-white px-4 py-2 rounded mt-4">
                                                View PanIDProof
                                            </button> : "Pan ID Proof not submitted"}
                                        </td>
                                        <td className="px-4 py-2">
                                            <button
                                                onClick={() => handleViewMember(member)}
                                                className="bg-green-500 text-white px-3 py-1 rounded"
                                            >
                                                View Details
                                            </button>
                                        </td>
                                        <td className="px-4 py-2">
                                            {!member.isApproved && (
                                                <>
                                                    <button
                                                        onClick={() => {openConfirmModal(() => { handleApproveRegistration(member._id);
                                                        
                                                    })
                                                    setAction("approve" )
                                                }}
                                                        className="bg-yellow-500 text-white px-3 py-1 rounded"
                                                    >
                                                        Approve
                                                    </button>
                                                    <button
                                                        onClick={() => {openConfirmModal(() => { handleDeleteMember(member._id);
                                                       })
                                                       setAction("delete")
                                                    }}
                                                        className="bg-red-500 text-white px-3 py-1 rounded ml-2"
                                                    >
                                                        Delete
                                                    </button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {isMemberModalVisible && selectedMember && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
                    <div className="bg-white p-6 rounded shadow-lg w-96">
                        <h2 className="text-2xl font-bold mb-4">{selectedMember.name} Details</h2>
                        <p><strong>Name:</strong> {selectedMember.name}</p>
                        <p><strong>Email:</strong> {selectedMember.email}</p>
                        <p><strong>Mobile:</strong> {selectedMember.mobile}</p>
                        <p><strong>Address:</strong> {selectedMember.address}</p>
                        <p className="notranslate"><strong>Plan:</strong> {selectedMember.plan || 'N/A'}</p>
                     
                        <div className="flex justify-end mt-6">
                            <button
                                onClick={() => setIsMemberModalVisible(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {confirmModalVisible && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
                    <div className="bg-white p-6 rounded shadow-lg w-96">
                        <h2 className="text-2xl font-bold mb-4">Confirm Action</h2>
                        <p>Are you sure you want to proceed to {action} this member?</p>
                        <div className="flex justify-end mt-6">
                            <button
                                onClick={() => setConfirmModalVisible(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                            >
                                Cancel
                            </button>
                        
                            <button
                                onClick={handleConfirmAction}
                                className="bg-green-500 text-white px-4 py-2 rounded"
                            >
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {isUpdatePlanModalVisible && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
                    <div className="bg-white p-6 rounded shadow-lg w-96">
                        <h2 className="text-2xl font-bold mb-4">Update Plan</h2>
                        <p>Update plan for {selectedMember.name}</p>
                        <p>Current Plan: {selectedMember.plan||"None"}</p>
                        {selectedMember.requestedPlan&& <p>Request Plan: {selectedMember.requestedPlan}</p>}
                        <select
                            value={newPlan}
                            onChange={(e) => setNewPlan(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded mb-4"
                        >
                            <option value="">Select Plan</option>
                            {getAvailablePlans(selectedMember.plan).map((plan) => (
                                <option key={plan} value={plan}>{plan}</option>
                            ))}
                        </select>
                        <div className="flex justify-end mt-6">
                            <button
                                onClick={() => setIsUpdatePlanModalVisible(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleUpdatePlan}
                                className="bg-green-500 text-white px-4 py-2 rounded"
                            >
                                Update
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default NetworkPage;
