import { useEffect, useState } from 'react';
import { useAuth } from '../hooks/AuthProvider';
import axios from '../utils/api';
import { useNotifications } from '../context/NotificationContext';
import FileInput from './basic/FileInput';

export default function AllMemberCommissions() {
    const user = useAuth();
    const [allMembersCommission, setAllMembersCommission] = useState([]);
    const [paymentProof, setPaymentProof] = useState(null);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showCommissionHistoryModal, setShowCommissionHistoryModal] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);
    const [memberId, setMemberId] = useState(null);
    const [index, setIndex] = useState(null);
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const {fetchNotifications}= useNotifications();

    const fetchCommissions = async () => {
        try {
            const response = await axios.get('/member/commissions', {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            });
            setAllMembersCommission(response.data);
        } catch (error) {
            console.error('Error fetching commissions:', error);
        }
    };

    const openHandleApproveModal = (memberId, index) => {
        setMemberId(memberId);
        setIndex(index);
        setShowApproveModal(true);
    };

    const handleApprove = async () => {
        try {
            const form = new FormData();
            if (paymentProof) {
                form.append('paymentProof', paymentProof);
            }
            form.append('status', 'Approved');
            form.append('commissionId', index);
            form.append('userId', memberId);
            const response = await axios.post('/member/update-commission-status', form, {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                    'Content-Type': 'multipart/form-data'
                }
            });
            setPaymentProof(null);
            setShowApproveModal(false);
            const updatedMember = { ...selectedMember };
            updatedMember.commissionHistory[index].status = 'Approved';
            fetchCommissions();
            fetchNotifications();
        } catch (error) {
            console.error('Error approving commission:', error);
        }
    };

    const handleReject = async (memberId, index) => {
        try {
            const response = await axios.post('/member/update-commission-status', {
                userId: memberId,
                commissionId: index,
                status: 'Rejected'
            }, {
                headers: {
                    Authorization: `Bearer ${user.token}`
                }
            });
            // Update the selected member's commission history immediately
            const updatedMember = { ...selectedMember };
            updatedMember.commissionHistory[index].status = 'Rejected';
            setSelectedMember(updatedMember);
            fetchCommissions();
            fetchNotifications();
        } catch (error) {
            console.error('Error rejecting commission:', error);
        }
    };

    const handleViewCommissionHistory = (member) => {
        setSelectedMember(member);
        setShowCommissionHistoryModal(true);
    };

    useEffect(() => {
        fetchCommissions();
    }, []);

    return (
        <>
            {showApproveModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
                    <div className="bg-white p-6 rounded shadow-lg w-96">
                        <h2 className="text-2xl font-bold mb-4">Approve Commission</h2>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            handleApprove();
                        }}>
                            <div className="mb-4">
                                <label className="block text-sm font-medium text-gray-700">Payment Proof</label>
                                <FileInput
                                    accept="image/*"
                                    onChange={(e) => {
                                        setPaymentProof(e.target.files[0]);
                                    }}
                                    className="block w-full mt-1 p-2 border rounded"
                                />
                            </div>
                            <div className="flex justify-end">
                                <button
                                    type="button"
                                    onClick={() => setShowApproveModal(false)}
                                    className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="bg-blue-500 text-white px-4 py-2 rounded"
                                >
                                    Approve
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {showCommissionHistoryModal && selectedMember && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-40">
                    <div className="bg-white p-6 rounded shadow-lg w-full max-w-3xl">
                        <h2 className="text-2xl font-bold mb-4">{selectedMember.name}'s Commission History</h2>
                        <div className="h-[70vh] overflow-auto">
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className="bg-gray-200">
                                        <th className="px-4 py-2 text-left">Date</th>
                                        <th className="px-4 py-2 text-left">Amount</th>
                                        <th className="px-4 py-2 text-left">Status</th>
                                        <th className="px-4 py-2 text-left">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedMember.commissionHistory.map((commission, i) => (
                                        <tr key={i} className="border-b">
                                            <td className="px-4 py-2">{new Date(commission.date).toLocaleDateString()}</td>
                                            <td className="px-4 py-2">Rs. {commission.amount}</td>
                                            <td className="px-4 py-2">{commission.status}</td>
                                            <td className="px-4 py-2">
                                                {commission.status === 'Requested' && (
                                                    <>
                                                        <button
                                                            className="bg-green-500 text-white px-2 py-1 rounded mr-2"
                                                            onClick={() => openHandleApproveModal(selectedMember._id, i)}
                                                        >
                                                            Approve
                                                        </button>
                                                        <button
                                                            className="bg-red-500 text-white px-2 py-1 rounded"
                                                            onClick={() => handleReject(selectedMember._id, i)}
                                                        >
                                                            Reject
                                                        </button>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="flex justify-end mt-6">
                            <button
                                onClick={() => setShowCommissionHistoryModal(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div>
                <h2 className="text-2xl font-bold mb-4">Member Commission History</h2>
                <div className="mb-4">
                    <button
                        onClick={() => setIsAccordionOpen(!isAccordionOpen)}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                    >
                        {isAccordionOpen ? 'Hide Members Commissions' : 'Show Members Commissions'}
                    </button>
                </div>
                {isAccordionOpen && (
                    <table className="table-auto w-full">
                        <thead>
                            <tr className="bg-gray-200">
                                <th className="px-4 py-2 text-left">Name</th>
                                <th className="px-4 py-2 text-left">Commission</th>
                                <th className="px-4 py-2 text-left">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allMembersCommission.map((member, i) => {
                                const hasPendingRequest = member.commissionHistory.some(commission => commission.status === 'Requested');
                                return (
                                    <tr key={i} className="border-b">
                                        <td className="px-4 py-2">{member.name}</td>
                                        <td className="px-4 py-2">Rs. {member.commissionHistory.reduce((acc, commission) => acc + commission.amount, 0)}</td>
                                        <td className="px-4 py-2">
                                            <button
                                                className={`px-2 py-1 rounded ${hasPendingRequest ? 'bg-yellow-500' : 'bg-blue-500'} text-white`}
                                                onClick={() => handleViewCommissionHistory(member)}
                                            >
                                                {hasPendingRequest ? 'View Commission History (pending request)' : 'View Commission History'}
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                )}
            </div>
        </>
    );
}
