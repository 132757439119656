import { useState } from "react";

export default function FileInput({ onChange, ...props }) {
    const [error, setError] = useState('');
    const fileValidation = (e) => {
        const file = e.target.files[0]; // Accessing the file correctly
            if (file && file.size > 5 * 1024 * 1024) { // Check if file size exceeds 5MB
                setError('File size exceeds 5MB limit.');
                e.target.value = null;
                return;
            }
            setError(''); // Clear error message for valid files
            onChange(e); // Call the onChange prop with the event
    };

    return (
        <>
            <p style={{ color: '#da683c' }}>{error}</p>
            <input {...props} type="file" onChange={fileValidation} />
        </>
    );
}
